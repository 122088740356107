import React, { Component } from "react";
import { Row, Col, Button, Card, CardHeader, CardBody, FormGroup, Label } from "reactstrap";
import { MDBDataTable } from "mdbreact";

import { getBackend } from "../../helpers/Backend";


let Backend;



class Players extends Component {

    constructor(props) {

        super(props);


        this.state = {
            network: {},
            networks: [],
            operator: {},
            operators: [],
            provider: null,
            providers: [],
            disabled: false,
            loading: false,
            alert: null,
            table: null,
            players: []
        };


        Backend = getBackend();

        this.authAccount = Backend.getAuthenticatedUser();

        this.RenderTable = this.RenderTable.bind(this);
    }



    componentDidMount() {

        if (this.authAccount.type === 0 && this.authAccount.id === 1)
            Backend.getProviders((data) => {
                this.setState({ providers: data });
                if (data != null && data.length == 1) this.setState({ provider: data[0] });
            });
        else {
            if (this.authAccount.providers != null && this.authAccount.providers.length == 1)
                this.setState({ provider: this.authAccount.providers[0], providers: this.authAccount.providers });
            else
                this.setState({ providers: this.authAccount.providers });
        }

        if (this.authAccount.type < 1) {

            Backend.getAccounts("network", (data) => { this.setState({ networks: data }) });
            Backend.getAccounts("operator", (data) => { this.setState({ operators: data }) });
        }
        else if (this.authAccount.type < 3)
            Backend.getAccounts("operator", (data) => { this.setState({ operators: data }) });
    }



    RenderTable(data) {

        let table = {};

        if (this.state.provider !== null) {

            table = {
                columns: [
                    {
                        label: "IntID",
                        field: "id",
                        width: 150
                    }, {
                        label: "ExtID",
                        field: "extID",
                        width: 150
                    }, {
                        label: "Network",
                        field: "network",
                        width: 150
                    }, {
                        label: "Operator",
                        field: "operator",
                        width: 150
                    }, {
                        label: "Partner ID",
                        field: "pid",
                        width: 150
                    }, {
                        label: "Username",
                        field: "name",
                        width: 150
                    }, {
                        label: "Last Session",
                        field: "date",
                        width: 150
                    }, {
                        label: "Last Balance",
                        field: "balance",
                        width: 150
                    }],
                rows: []
            }

            for (let i = 0; i < data.length; i++) {

                let provider = data[i];

                for (let j = 0; j < provider.players.length; j++) {

                    let player = provider.players[j];
                    let date = new Date(player.last_access);

                    let operator = player.client;

                    player.network = operator.parent;
                    player.operator = operator.name;

                    player.date = date.toLocaleDateString() + " " + date.toLocaleTimeString();
                    player.balance = player.balance != null ? player.balance.toFixed(2) : "0.00";

                    table.rows.push(player);
                }
            }

            this.setState({ table: <MDBDataTable responsive striped bordered entries={100} order={["id", "asc"]} data={table} /> });
        }
    }



    GetOperators(index) {

        let list = [];
        let self = this;

        this.setState({ operator: {} });

        if (index >= 0) {

            let network = this.state.networks[index];

            Backend.getAccounts("operator", (data) => {

                data.forEach((operator, index) => {

                    if (operator.parent === network.username)
                        list.push(operator);

                    if (index === data.length - 1)
                        self.setState({ operators: list });
                });
            });
        }
        else
            Backend.getAccounts("operator", (data) => { this.setState({ operators: data }) });
    }



    async GetPlayers() {

        let operator = this.state.operator;
        let provider = this.state.providers.length === 1 ? this.state.providers[0] : this.state.provider;

        if (provider !== null) {

            this.setState({ table: null, loading: true });

            if (operator != null && operator.id != null && operator.id > 0) {

                Backend.getPlayers(provider.vendor.name, provider.name, operator.username, (data) => {

                    this.RenderTable([{ provider: provider, players: data }]);
                    this.setState({ loading: false });
                });
            }
            else {

                Backend.getPlayers(provider.vendor.name, provider.name, null, (data) => {

                    this.RenderTable([{ provider: provider, players: data }]);
                    this.setState({ loading: false });
                });
            }
        }
    }



    render() {

        let { networks, operators, providers } = this.state;

        return (
            <React.Fragment>
                <div className="container-fluid">
                    <Row className="align-items-center">
                        <Col sm="6">
                            <div className="page-title-box">
                                <h4 className="font-size-18">Players List</h4>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg="12">
                            <Card>
                                <CardHeader>
                                    {this.authAccount.type > 0 ?
                                        <FormGroup row>
                                            <Col sm="6">
                                                <Label htmlFor="select">Providers:</Label>
                                                <select defaultValue={providers.length === 1 ? "0" : ""} className="form-control" onChange={(e) => parseInt(e.target.value) >= 0 ? this.setState({ provider: this.state.providers[parseInt(e.target.value)] }) : this.setState({ provider: null })} >
                                                    {providers.length === 1 ?
                                                        <option key={0} value={"0"}>{providers[0].name}</option> :
                                                        <option value={""}>Select Provider</option>}
                                                    {providers.length !== 1 ?
                                                        providers.map((provider, index) => (
                                                            <option key={index} value={index}>{provider.name}</option>
                                                        )) : null}
                                                </select>
                                            </Col>
                                            <Col sm="6">
                                                <Label htmlFor="select">Operators:</Label>
                                                <select defaultValue={-1} className="form-control" onChange={(e) => this.setState({ operator: this.state.operators[parseInt(e.target.value)] })} >
                                                    <option value={-1}>All Operators</option>
                                                    {operators.map((operator, index) => (
                                                        <option key={index} value={index}>{operator.username}</option>
                                                    ))}
                                                </select>
                                            </Col>
                                        </FormGroup> :
                                        <FormGroup row>
                                            <Col sm="4">
                                                <Label htmlFor="select">Providers:</Label>
                                                <select defaultValue={providers.length === 1 ? "0" : ""} className="form-control" onChange={(e) => parseInt(e.target.value) >= 0 ? this.setState({ provider: this.state.providers[parseInt(e.target.value)] }) : this.setState({ provider: null })} >
                                                    {providers.length === 1 ?
                                                        <option key={0} value={"0"}>{providers[0].vendor.name !== providers[0].name ? providers[0].vendor.name + " " + providers[0].name : providers[0].name}</option> :
                                                        <option value={""}>Select Provider</option>}
                                                    {providers.length !== 1 ?
                                                        providers.map((provider, index) => (
                                                            <option key={index} value={index}>{provider.vendor.name !== provider.name ? provider.vendor.name + " " + provider.name : provider.name}</option>
                                                        )) : null}
                                                </select>
                                            </Col>
                                            <Col sm="4">
                                                <Label htmlFor="select">Networks:</Label>
                                                <select defaultValue={-1} className="form-control" onChange={(e) => this.GetOperators(parseInt(e.target.value)) }  >
                                                    <option value={-1}>All Networks</option>
                                                    {networks.map((network, index) => (
                                                        <option key={index} value={index}>{network.username}</option>
                                                    ))}
                                                </select>
                                            </Col>
                                            <Col sm="4">
                                                <Label htmlFor="select">Operators:</Label>
                                                <select defaultValue={-1} className="form-control" onChange={(e) => this.setState({ operator: this.state.operators[parseInt(e.target.value)] })} >
                                                    <option value={-1}>All Operators</option>
                                                    {operators.map((operator, index) => (
                                                        <option key={index} value={index}>{operator.username}</option>
                                                    ))}
                                                </select>
                                            </Col>
                                        </FormGroup>
                                    }
                                    <FormGroup row>
                                        <Col sm="2">
                                            <Button color="primary" className="btn btn-primary waves-effect waves-light" onClick={() => this.GetPlayers()} ><i className="mdi mdi-card-search-outline"></i>{" "}Search</Button>
                                        </Col>
                                    </FormGroup>
                                </CardHeader>
                                <CardBody>
                                    {this.state.table || (this.state.loading ? <span>Loading Data...</span> : <span>No Data Available</span>)}
                                </CardBody>
                            </Card>
                        </Col >
                    </Row>
                </div>
            </React.Fragment >
        );
    }
}

export default Players; 