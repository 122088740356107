import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import MetisMenu from "metismenujs";

import SimpleBar from "simplebar-react";

import { getBackend } from "../../helpers/Backend";



const SidebarContent = props => {

    let Backend = getBackend();

    let account = Backend.getAuthenticatedUser();

    return (
        <div id="sidebar-menu">
            <ul className="metismenu list-unstyled" id="side-menu">
                <li className="menu-title">Main</li>

                <li>
                    <Link to="/dashboard" className="waves-effect">
                        <i className="ti-home"></i>
                        <span>Dashboard</span>
                    </Link>
                </li>

                {!account.support ?
                    <li>
                        <Link to="/#" className="has-arrow waves-effect">
                            <i className="ti-calendar"></i>
                            <span>Report</span>
                        </Link>
                        <ul className="sub-menu">
                            <li>
                                <Link to="/accounts/reports/daily">Daily</Link>
                            </li>
                            <li>
                                <Link to="/accounts/reports/weekly">Weekly</Link>
                            </li>
                            <li>
                                <Link to="/accounts/reports/monthly">Monthly</Link>
                            </li>
                            <li>
                                <Link to="/accounts/reports/custom">Custom</Link>
                            </li>
                        </ul>
                    </li> : null
                }

                <li>
                    <Link to="/#" className="has-arrow waves-effect">
                        <i className="ti-email"></i>
                        <span>Message</span>
                    </Link>
                    <ul className="sub-menu">
                        <li>
                            <Link to="/email/inbox">Inbox</Link>
                        </li>
                        <li>
                            <Link to="/email/read">Read</Link>
                        </li>
                        <li>
                            <Link to="/email/compose">Compose</Link>
                        </li>
                    </ul>
                </li>


                <li className="menu-title">Accounts</li>

                {account.type === 0 ?
                    <li>
                        <Link to="/accounts/reseller" className="waves-effect">
                            <i className="ti-user"></i>
                            <span>Resellers</span>
                        </Link>
                    </li> : null
                }

                {account.type < 2 ?
                    <li>
                        <Link to="/accounts/network" className="waves-effect">
                            <i className="ti-server"></i>
                            <span>Networks</span>
                        </Link>
                    </li> : null
                }

                {account.type < 3 ?
                    <li>
                        <Link to="/accounts/operator" className="waves-effect">
                            <i className="fas fa-globe"></i>
                            <span>Operators</span>
                        </Link>
                    </li> : null
                }

                <li>
                    <Link to="/accounts/player" className="waves-effect">
                        <i className="fas fa-users"></i>
                        <span>Players</span>
                    </Link>
                </li>


                <li className="menu-title">Providers</li>

                {account.type === 0 && account.id === 1 ?
                    <li>
                        <Link to="/providers/list" className="waves-effect">
                            <i className="ti-layers-alt"></i>
                            <span>List</span>
                        </Link>
                    </li> : null}

                {account.type === 0 && account.id === 1 ?
                    <li>
                        <Link to="/providers/vendor" className="waves-effect">
                            <i className="ti-shopping-cart"></i>
                            <span>Vendors</span>
                        </Link>
                    </li> : null}

                {account.type === 0 && account.id === 1 ?
                    <li>
                        <Link to="/providers/configuration" className="waves-effect">
                            <i className="ti-settings"></i>
                            <span>Configurations</span>
                        </Link>
                    </li> : null}

                <li>
                    <Link to="/#" className="has-arrow waves-effect">
                        <i className="fas fa-shopping-bag"></i>
                        <span>Games</span>
                    </Link>
                    <ul className="sub-menu">
                        <li>
                            <Link to="/providers/games">List</Link>
                        </li>
                        <li>
                            <Link to="/providers/games/jackpot">Jackpot</Link>
                        </li>
                        <li>
                            <Link to="/providers/games/freespin">Freespin</Link>
                        </li>
                        <li>
                            <Link to="/providers/games/bonus">Bonus</Link>
                        </li>
                    </ul>
                </li>

                {/*!account.support ?*/
                    <li>
                        <Link to="/#" className="has-arrow waves-effect">
                            <i className="ti-calendar"></i>
                            <span>Report</span>
                        </Link>
                        <ul className="sub-menu">
                            <li>
                                <Link to="/providers/reports/daily">Daily</Link>
                            </li>
                            <li>
                                <Link to="/providers/reports/weekly">Weekly</Link>
                            </li>
                            <li>
                                <Link to="/providers/reports/monthly">Monthly</Link>
                            </li>
                            <li>
                                <Link to="/providers/reports/custom">Custom</Link>
                            </li>
                        </ul>
                    </li> /*: null */
                }


                <li className="menu-title">Support</li>

                <li>
                    <Link to="/#" className="has-arrow waves-effect">
                        <i className="fas fa-tools"></i>
                        <span>Ticket</span>
                    </Link>
                    <ul className="sub-menu">
                        <li>
                            <Link to="/ticket/new">
                                New
                                <span className="badge badge-pill badge-danger float-right">
                                    6
                                </span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/ticket/pending">
                                Pending
                                <span className="badge badge-pill badge-warning float-right">
                                    3
                                </span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/ticket/closed">
                                Closed
                                <span className="badge badge-pill badge-success float-right">
                                    33
                                </span>
                            </Link>
                        </li>
                    </ul>
                </li>

                <li>
                    <Link to="/support/transactions" className="waves-effect">
                        <i className="fas fa-money-bill"></i>
                        <span>Transactions</span>
                    </Link>
                </li>

                <li>
                    <Link to="/support/played" className="waves-effect">
                        <i className="ti-game"></i>
                        <span>Games Played</span>
                    </Link>
                </li>

                <li>
                    <Link to="/support/rollbacks" className="waves-effect">
                        <i className="mdi mdi-recycle"></i>
                        <span>Rollbacks</span>
                    </Link>
                </li>

                <li>
                    <Link to="/support/warnings" className="waves-effect">
                        <i className="fas fa-exclamation-triangle"></i>
                        <span>Warnings</span>
                        {/*<span className="badge badge-pill badge-danger float-right">
                            +99
                        </span>*/}
                    </Link>
                </li>

                <li>
                    <Link to="/support/logs" className="waves-effect">
                        <i className="ti-notepad"></i>
                        <span>Logs</span>
                    </Link>
                </li>

                {account.type === 0 && !account.support ?
                    <li>
                        <Link to="/support/account" className="waves-effect">
                            <i className="ti-heart-broken"></i>
                            <span>Account</span>
                        </Link>
                    </li> : null}



                {/*account.type === 0 && account.id === 1 ?
                    <>
                        <li className="menu-title">Components</li>
                        <li>
                            <Link to="/#" className="has-arrow waves-effect">
                                <i className="ti-package"></i>
                                <span>UI Elements</span>
                            </Link>
                            <ul className="sub-menu">
                                <li>
                                    <Link to="ui-alerts">Alerts</Link>
                                </li>
                                <li>
                                    <Link to="ui-buttons">Buttons</Link>
                                </li>
                                <li>
                                    <Link to="ui-cards">Cards</Link>
                                </li>
                                <li>
                                    <Link to="ui-carousel">Carousel</Link>
                                </li>
                                <li>
                                    <Link to="ui-dropdowns">Dropdowns</Link>
                                </li>
                                <li>
                                    <Link to="ui-grid">Grid</Link>
                                </li>
                                <li>
                                    <Link to="ui-images">Images</Link>
                                </li>
                                <li>
                                    <Link to="ui-lightbox">Lightbox</Link>
                                </li>
                                <li>
                                    <Link to="ui-modals">Modals</Link>
                                </li>
                                <li>
                                    <Link to="ui-rangeslider">Range Slider</Link>
                                </li>
                                <li>
                                    <Link to="ui-session-timeout">Session Timeout</Link>
                                </li>
                                <li>
                                    <Link to="ui-progressbars">Progress Bars</Link>
                                </li>
                                <li>
                                    <Link to="ui-sweet-alert">Sweet-Alert</Link>
                                </li>
                                <li>
                                    <Link to="ui-tabs-accordions">Tabs &amp; Accordions</Link>
                                </li>
                                <li>
                                    <Link to="ui-typography">Typography</Link>
                                </li>
                                <li>
                                    <Link to="ui-video">Video</Link>
                                </li>
                                <li>
                                    <Link to="ui-general">General</Link>
                                </li>
                                <li>
                                    <Link to="ui-colors">Colors</Link>
                                </li>
                                <li>
                                    <Link to="ui-rating">Rating</Link>
                                </li>
                            </ul>
                        </li>

                        <li>
                            <Link to="/#" className="waves-effect">
                                <i className="ti-receipt"></i>
                                <span className="badge badge-pill badge-success float-right">
                                    6
                        </span>
                                <span>Forms</span>
                            </Link>
                            <ul className="sub-menu">
                                <li>
                                    <Link to="form-elements">Form Elements</Link>
                                </li>
                                <li>
                                    <Link to="form-validation">Form Validation</Link>
                                </li>
                                <li>
                                    <Link to="form-advanced">Form Advanced</Link>
                                </li>
                                <li>
                                    <Link to="form-editors">Form Editors</Link>
                                </li>
                                <li>
                                    <Link to="form-uploads">Form File Upload</Link>
                                </li>
                                <li>
                                    <Link to="form-xeditable">Form Xeditable</Link>
                                </li>
                                <li>
                                    <Link to="form-repeater">Form Repeater</Link>
                                </li>
                                <li>
                                    <Link to="form-wizard">Form Wizard</Link>
                                </li>
                                <li>
                                    <Link to="form-mask">Form Mask</Link>
                                </li>
                            </ul>
                        </li>

                        <li>
                            <Link to="/#" className="has-arrow waves-effect">
                                <i className="ti-pie-chart"></i>
                                <span>Charts</span>
                            </Link>
                            <ul className="sub-menu">
                                <li>
                                    <Link to="charts-chartist">Chartist Chart</Link>
                                </li>
                                <li>
                                    <Link to="e-charts">E Chart</Link>
                                </li>
                                <li>
                                    <Link to="charts-chartjs">Chartjs Chart</Link>
                                </li>
                                <li>
                                    <Link to="apex-chart">Apex Chart</Link>
                                </li>
                                <li>
                                    <Link to="charts-sparkline">Sparkline Chart</Link>
                                </li>
                            </ul>
                        </li>

                        <li>
                            <Link to="/#" className="has-arrow waves-effect">
                                <i className="ti-view-grid"></i>
                                <span>Tables</span>
                            </Link>
                            <ul className="sub-menu">
                                <li>
                                    <Link to="tables-basic">Basic Tables</Link>
                                </li>
                                <li>
                                    <Link to="tables-datatable">Data Table</Link>
                                </li>
                                <li>
                                    <Link to="tables-responsive">Responsive Table</Link>
                                </li>
                                <li>
                                    <Link to="tables-editable">Editable Table</Link>
                                </li>
                            </ul>
                        </li>

                        <li>
                            <Link to="/#" className="has-arrow waves-effect">
                                <i className="ti-face-smile"></i>
                                <span>Icons</span>
                            </Link>
                            <ul className="sub-menu">
                                <li>
                                    <Link to="icons-material">Material Design</Link>
                                </li>
                                <li>
                                    <Link to="icons-fontawesome">Font Awesome</Link>
                                </li>
                                <li>
                                    <Link to="icons-ion">Ion Icons</Link>
                                </li>
                                <li>
                                    <Link to="icons-themify">Themify Icons</Link>
                                </li>
                                <li>
                                    <Link to="icons-dripicons">Dripicons</Link>
                                </li>
                                <li>
                                    <Link to="icons-typicons">Typicons Icons</Link>
                                </li>
                            </ul>
                        </li>

                        <li>
                            <Link to="/#" className="waves-effect">
                                <i className="ti-location-pin"></i>
                                <span className="badge badge-pill badge-danger float-right">2</span>
                                <span>Maps</span>
                            </Link>
                            <ul className="sub-menu">
                                <li>
                                    <Link to="maps-google"> Google Map</Link>
                                </li>
                                <li>
                                    <Link to="maps-vector"> Vector Map</Link>
                                </li>
                            </ul>
                        </li>

                        <li className="menu-title">Extras</li>

                        <li>
                            <Link to="/#" className="has-arrow waves-effect">
                                <i className="ti-archive"></i>
                                <span> Authentication </span>
                            </Link>
                            <ul className="sub-menu">
                                <li>
                                    <Link to="pages-login-2">Login 2</Link>
                                </li>
                                <li>
                                    <Link to="pages-register-2">Register 2</Link>
                                </li>
                                <li>
                                    <Link to="pages-recoverpw-2">Recover Password 2</Link>
                                </li>
                                <li>
                                    <Link to="pages-lock-screen-2">Lock Screen 2</Link>
                                </li>
                            </ul>
                        </li>

                        <li>
                            <Link to="/#" className="has-arrow waves-effect">
                                <i className="ti-support"></i>
                                <span> Extra Pages </span>
                            </Link>
                            <ul className="sub-menu">
                                <li>
                                    <Link to="pages-timeline">Timeline</Link>
                                </li>
                                <li>
                                    <Link to="pages-invoice">Invoice</Link>
                                </li>
                                <li>
                                    <Link to="pages-directory">Directory</Link>
                                </li>
                                <li>
                                    <Link to="pages-blank">Blank Page</Link>
                                </li>
                                <li>
                                    <Link to="pages-404">Error 404</Link>
                                </li>
                                <li>
                                    <Link to="pages-500">Error 500</Link>
                                </li>
                                <li>
                                    <Link to="pages-pricing">Pricing</Link>
                                </li>
                                <li>
                                    <Link to="pages-gallery">Gallery</Link>
                                </li>
                                <li>
                                    <Link to="pages-maintenance">Maintenance</Link>
                                </li>
                                <li>
                                    <Link to="pages-comingsoon">Coming Soon</Link>
                                </li>
                                <li>
                                    <Link to="pages-faq">FAQs</Link>
                                </li>
                            </ul>
                        </li>

                        <li>
                            <Link to="/#" className="has-arrow waves-effect">
                                <i className="ti-bookmark-alt"></i>
                                <span> Email Templates </span>
                            </Link>
                            <ul className="sub-menu">
                                <li>
                                    <Link to="/email-template-basic">Basic Action Email</Link>
                                </li>
                                <li>
                                    <Link to="/email-template-Alert">Alert Email</Link>
                                </li>
                                <li>
                                    <Link to="/email-template-Billing">Billing Email</Link>
                                </li>
                            </ul>
                        </li>

                        <li>
                            <Link to="/#" className="has-arrow waves-effect">
                                <i className="ti-more"></i>
                                <span>Multi Level</span>
                            </Link>
                            <ul className="sub-menu">
                                <li>
                                    <Link to="/#">Level 1.1</Link>
                                </li>
                                <li>
                                    <Link to="/#" className="has-arrow">
                                        Level 1.2
                            </Link>
                                    <ul className="sub-menu">
                                        <li>
                                            <Link to="/#">Level 2.1</Link>
                                        </li>
                                        <li>
                                            <Link to="/#">Level 2.2</Link>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                    </> : null*/}
            </ul>
        </div>
    );
};



class Sidebar extends Component {

    constructor(props) {

        super(props);

        this.state = {};
    }



    componentDidMount() {

        this.initMenu();
    }



    componentDidUpdate(prevProps) {

        if (this.props.type !== prevProps.type) {

            this.initMenu();
        }
    }



    initMenu() {

        if (this.props.type !== "condensed" || this.props.isMobile) {

            new MetisMenu("#side-menu");

            var matchingMenuItem = null;
            var ul = document.getElementById("side-menu");
            var items = ul.getElementsByTagName("a");

            for (var i = 0; i < items.length; ++i) {
                if (this.props.location.pathname === items[i].pathname) {
                    matchingMenuItem = items[i];
                    break;
                }
            }

            if (matchingMenuItem) {

                this.activateParentDropdown(matchingMenuItem);
            }
        }
    }



    activateParentDropdown = item => {

        item.classList.add("mm-active");
        const parent = item.parentElement;

        if (parent) {
            parent.classList.add("mm-active"); // li
            const parent2 = parent.parentElement;

            if (parent2) {
                parent2.classList.add("mm-show");

                const parent3 = parent2.parentElement;

                if (parent3) {
                    parent3.classList.add("mm-active"); // li
                    parent3.childNodes[0].classList.add("mm-active"); //a
                    const parent4 = parent3.parentElement;
                    if (parent4) {
                        parent4.classList.add("mm-active");
                    }
                }
            }
            return false;
        }
        return false;
    };



    render() {
        return (
            <React.Fragment>
                {this.props.type !== "condensed" ? (
                    <SimpleBar style={{ maxHeight: "100%" }}>
                        <SidebarContent />
                    </SimpleBar>
                ) : (
                        <SidebarContent />
                    )}
            </React.Fragment>
        );
    }
}


export default withRouter(Sidebar);