import React, { Component } from "react";
import SettingMenu from "../Shared/SettingMenu";
import { Row, Col, Button, Input, Card, CardBody, Progress } from "reactstrap";
import { Link } from "react-router-dom";
// Custom Scrollbar
import SimpleBar from "simplebar-react";

import { getBackend } from "../../helpers/Backend";

import InfoCard from "../../components/InfoCard";


// import images
import servicesIcon1 from "../../assets/images/services-icon/01.png";
import servicesIcon2 from "../../assets/images/services-icon/02.png";
import servicesIcon3 from "../../assets/images/services-icon/03.png";
import servicesIcon4 from "../../assets/images/services-icon/04.png";
import user2 from "../../assets/images/users/user-2.jpg";
import user3 from "../../assets/images/users/user-3.jpg";
import user4 from "../../assets/images/users/user-4.jpg";
import user5 from "../../assets/images/users/user-5.jpg";
import user6 from "../../assets/images/users/user-6.jpg";
import smimg1 from "../../assets/images/small/img-1.jpg";
import smimg2 from "../../assets/images/small/img-2.jpg";

// Charts
import LineAreaChart from "../AllCharts/apex/lineareachart";
import RadialChart from "../AllCharts/apex/apexdonut";
import Apexdonut from "../AllCharts/apex/apexdonut1";
import SparkLine from "../AllCharts/sparkline/sparkline";
import SparkLine1 from "../AllCharts/sparkline/sparkline1";
import Salesdonut from "../AllCharts/apex/salesdonut";

import "chartist/dist/scss/chartist.scss";
import account from "../../store/auth/register/reducer";



let Backend;
const currList = ["EUR", "USD", "GBP", "CAD", "CHF", "ALL", "TRY", "TND", "COP", "PEN", "BRL", "ARS", "BOB", "NGN", "MXN", "PYG", "ILS", "LBP", "ZMW", "TTC", "ZAR", "TZS", "RUB", "UAH", "HNL", "XOF", "MAD", "KES"];



class Dashboard extends Component {

    constructor(props) {

        super(props);

        this.state = {
            reseller: null,
            resellers: [],
            network: null,
            networks: [],
            operator: null,
            operators: [],
            provider: null,
            providers: [],
            affiliates: [],
            disabled: false,
            loading: false,
            alert: null,
            table: [],
            onLinePlayers: 0,
            playersData: {
                labels: [],
                series: [[]]
            },
            current: { bet: 0, win: 0},
            last: { bet: 0, win: 0 }
        };


        Backend = getBackend();

        this.authAccount = Backend.getAuthenticatedUser();
    }



    componentDidMount() {

        let currencies = JSON.parse(sessionStorage.getItem("currencies"));

        if (currencies == null || (currencies != null && currencies.length < currList.length)) {

            currencies = new Array();

            currList.forEach((currency) => {

                Backend.getCurrencyRatio(currency, (data) => { currencies.push({ currency: currency, rate: data }); sessionStorage.setItem("currencies", JSON.stringify(currencies)) });
            });
        }


        if (this.authAccount.type === 0 && this.authAccount.id === 1)
            Backend.getProviders((data) => { this.setState({ providers: data }); this.GetStatsTotal(data); });
        else
            this.setState({ providers: this.authAccount.providers });

        this.GetAccounts();

        this.GetOnlinePlayers();
    }



    GetAccounts() {

        if (this.authAccount.type === 0)
            Backend.getAccounts("reseller", (data) => { this.setState({ resellers: data }); Backend.getAccounts("network", (data) => { this.setState({ networks: data }); Backend.getAccounts("operator", (data) => { this.setState({ operators: data }); this.GetAffiliates(); }); }); });
        else if (this.authAccount.type === 1)
            Backend.getAccounts("network", (data) => { this.setState({ networks: data }); Backend.getAccounts("operator", (data) => { this.setState({ operators: data }); this.GetAffiliates(); }); });
        else
            Backend.getAccounts("operator", (data) => { this.setState({ operators: data }); this.GetAffiliates(); });
    }



    GetOnlinePlayers() {

        let self = this;
        let onLinePlayers = 0;
        let playersData = {
            labels: [],
            series: [[]]
        };


        Backend.getOnlinePlayers((data) => {

            data.forEach((item, index) => {

                if (item.online > 0) {

                    playersData.labels.push(item.provider.name);
                    playersData.series[0].push(item.online);

                    onLinePlayers += item.online;
                }

                if (index == data.length - 1)
                    self.setState({ onLinePlayers: onLinePlayers, playersData: playersData });
            });
        });
    }



    GetAffiliates() {

        let list = [];
        let affiliates = [];


        for (let i = 0; i < this.state.resellers.length; i++) {

            list = [];
            let reseller = this.state.resellers[i];

            for (let j = 0; j < this.state.networks.length; j++) {

                let network = this.state.networks[j];

                for (let k = 0; k < this.state.operators.length; k++) {

                    let operator = this.state.operators[k];

                    if (operator.parent === network.username && network.parent === reseller.username)
                        list.push(operator);
                }
            }

            if (list.length > 0)
                affiliates.push({ username: reseller.username, operators: list });

            list = [];

            for (let k = 0; k < this.state.operators.length; k++) {

                let operator = this.state.operators[k];

                if (operator.parent === reseller.username)
                    list.push(operator);
            }

            if (list.length > 0 && affiliates.findIndex(a => a.username == reseller.username) < 0)
                affiliates.push({ username: reseller.username, operators: list });
        }


        for (let j = 0; j < this.state.networks.length; j++) {

            let network = this.state.networks[j];

            if (network.parent === this.authAccount.username) {

                list = [];

                for (let k = 0; k < this.state.operators.length; k++) {

                    let operator = this.state.operators[k];

                    if (operator.parent === network.username)
                        list.push(operator);
                }

                if (list.length > 0 && affiliates.findIndex(a => a.username == network.username) < 0)
                    affiliates.push({ username: network.username, operators: list });
            }
        }


        list = [];

        for (let k = 0; k < this.state.operators.length; k++) {

            let operator = this.state.operators[k];

            if (operator.parent === this.authAccount.username)
                list.push(operator);
        }

        if (list.length > 0 && affiliates.findIndex(a => a.username == this.authAccount.username) < 0)
            affiliates.push({ username: this.authAccount.username, operators: list });


        this.setState({ affiliates: affiliates });
    }



    GetStatsTotal(providers) {

        let current;
        let last;

        let now = new Date();
        let start = new Date(now.getFullYear(), now.getMonth(), 1);
        let end = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59);
        end.setDate(end.getDate() - 1);
        let bet = 0;
        let win = 0;
        let currencies = JSON.parse(sessionStorage.getItem("currencies"));

        let index = 0;

        for (let j = 0; j < providers.length; j++) {

            let provider = providers[j];

            Backend.getReports(provider.vendor.name, provider.name, null, JSON.stringify(start).split('T')[0].split('"')[1] + "T00:00:00", JSON.stringify(end).split('T')[0].split('"')[1] + "T23:59:59",
                (data) => {

                    if (data) {
                        for (let i = 0; i < data.length; i++) {

                            let currency = currencies[currencies.findIndex(p => p.currency == data[i].currency)];

                            if (!currency)
                                currency = { currency: 'EUR', rate: 1 };

                            bet += (data[i].bet / currency.rate);
                            win += (data[i].win / currency.rate);
                        }

                        index++;

                        if (index == providers.length) {

                            index = 0;
                            current = { bet: bet, win: win };

                            start.setMonth(start.getMonth() - 1);
                            end.setMonth(end.getMonth() - 1);
                            let lBet = 0;
                            let lWin = 0;

                            for (let k = 0; k < providers.length; k++) {

                                provider = providers[k];

                                Backend.getReports(provider.vendor.name, provider.name, null, JSON.stringify(start).split('T')[0].split('"')[1] + "T00:00:00", JSON.stringify(end).split('T')[0].split('"')[1] + "T23:59:59",
                                    (data) => {
                                        for (let i = 0; i < data.length; i++) {

                                            let currency = currencies[currencies.findIndex(p => p.currency == data[i].currency)];

                                            if (!currency)
                                                currency = { currency: 'EUR', rate: 1 };

                                            lBet += (data[i].bet / currency.rate);
                                            lWin += (data[i].win / currency.rate);
                                        }

                                        index++;

                                        if (index == providers.length) {

                                            last = { bet: lBet, win: lWin };

                                            this.setState({ current: current, last: last });
                                        }
                                    },
                                    () => { });
                            }
                        }
                    }
                },
                () => { });
        }
    }



    render() {

        let bet = this.state.current.bet;
        let win = this.state.current.win;

        let ggr = bet - win;
        let lastGGR = this.state.last.bet - this.state.last.win;
        let disc = lastGGR * 100 / ggr;

        let rtp = win * 100 / bet;
        let lastRTP = this.state.last.win * 100 / this.state.last.bet;
        let discRTP = lastRTP * 100 / rtp;

        return (
            <React.Fragment>
                <div className="container-fluid">
                    <Row className="align-items-center">
                        <Col sm={6}>
                            <div className="page-title-box">
                                <h4 className="font-size-18">Dashboard</h4>
                                <ol className="breadcrumb mb-0">
                                    <li className="breadcrumb-item active">
                                        Welcome to RiseUP Games Dashboard
                                    </li>
                                </ol>
                            </div>
                        </Col>

                        {/*<Col sm="6">
                            <div className="float-right d-none d-md-block">
                                <SettingMenu />
                            </div>
                        </Col>*/}
                    </Row>

                    <Row>
                        <Col xl={3} md={6}>
                            <InfoCard title="TOTAL BET (MONTHLY)" value={bet.toFixed(2)} arrow={bet >= this.state.last.bet ? "UP" : "DOWN"} icon={<i className="fas fa-euro-sign fa-3x" style={{ marginTop: "8px" }} />} />
                        </Col>
                        <Col xl={3} md={6}>
                            <InfoCard title="TOTAL WIN (MONTHLY)" value={win.toFixed(2)} arrow={win >= this.state.last.win ? "UP" : "DOWN"} icon={<i className="fas fa-euro-sign fa-3x" style={{ marginTop: "8px" }} />} />
                        </Col>
                        <Col xl={3} md={6}>
                            <InfoCard title="TOTAL GGR (EUR)" value={ggr.toFixed(2)} arrow={ggr >= lastGGR ? "UP" : "DOWN"} icon={<i className="fas fa-chart-line fa-3x" style={{ marginTop: "8px" }} />} badge={disc <= 100 ? { value: "+" + disc.toFixed(0) + "%", color: "success" } : { value: "-" + (disc - 100).toFixed(0) + "%", color: "danger" }} />
                        </Col>
                        <Col xl={3} md={6}>
                            <InfoCard title="RETURN TO PLAYERS" value={(win * 100 / bet).toFixed(2) + "%"} arrow={rtp >= lastRTP ? "UP" : "DOWN"} icon={<i className="fas fa-balance-scale fa-3x" style={{ marginTop: "8px" }} />} badge={discRTP <= 100 ? { value: "+" + discRTP.toFixed(0) + "%", color: "success" } : { value: "-" + (discRTP - 100).toFixed(0) + "%", color: "danger" }} />
                        </Col>
                    </Row>

                    <Row>
                        <Col xl={12}>
                            <Card>
                                <CardBody>
                                    <h4 className="card-title mb-4">Online Players ({this.state.onLinePlayers})</h4>
                                    <Row>
                                        <Col lg={12}>
                                            <div>
                                                <LineAreaChart data={this.state.playersData} type={"Bar"} />
                                            </div>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                    <Row>
                        <Col xl={12}>
                            <Card>
                                <CardBody>
                                    <h4 className="card-title mb-4">Monthly Earning</h4>
                                    <Row>
                                        <Col lg={7}>
                                            <div>
                                                <LineAreaChart data={{
                                                    labels: [],
                                                    series: [[]]
                                                }} />
                                            </div>
                                        </Col>
                                        <Col lg={5}>
                                            <Row>
                                                <Col md={6}>
                                                    <div className="text-center">
                                                        <p className="text-muted mb-4">This month</p>
                                                        <h3>€ 0.00</h3>
                                                        <p className="text-muted mb-5">
                                                            GGR value (EUR), below BET \ WIN ratio
                                                        </p>
                                                        <RadialChart />
                                                    </div>
                                                </Col>
                                                <Col md={6}>
                                                    <div className="text-center">
                                                        <p className="text-muted mb-4">Last month</p>
                                                        <h3>€ 0.00</h3>
                                                        <p className="text-muted mb-5">
                                                            GGR value (EUR), below BET \ WIN ratio
                                                        </p>
                                                        <Apexdonut />
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                    {/*<Row>
                        <Col xl={6}>
                            <Card>
                                <CardBody>
                                    <div>
                                        <h4 className="card-title mb-4">Earnings Overview</h4>
                                    </div>
                                    <div className="wid-peity mb-4">
                                        <div className="row">
                                            <div className="col-md-4">
                                                <div>
                                                    <p className="text-muted">Daily</p>
                                                    <h5 className="mb-4">1542</h5>
                                                </div>
                                            </div>
                                            <div className="col-md-8">
                                                <div className="mb-4">
                                                    <SparkLine />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="wid-peity mb-4">
                                        <div className="row">
                                            <div className="col-md-4">
                                                <div>
                                                    <p className="text-muted">Weekly</p>
                                                    <h5 className="mb-4">6451</h5>
                                                </div>
                                            </div>
                                            <div className="col-md-8">
                                                <div className="mb-4">
                                                    <SparkLine1 />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="">
                                        <div className="row">
                                            <div className="col-md-4">
                                                <div>
                                                    <p className="text-muted">Monthly</p>
                                                    <h5>84574</h5>
                                                </div>
                                            </div>
                                            <div className="col-md-8">
                                                <div className="mb-4">
                                                    <SparkLine />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col xl={6}>
                            <Card>
                                <CardBody>
                                    <h4 className="card-title mb-4">Revenue Sources</h4>
                                    <div className="cleafix">
                                        <p className="float-left">
                                            <i className="mdi mdi-calendar mr-1 text-primary"></i> Feb
                                            01 - Feb 28
                                        </p>
                                        <h5 className="font-18 text-right">€ 0.00</h5>
                                    </div>
                                    <div id="ct-donut" className="ct-chart wid pt-4">
                                        <Salesdonut />
                                    </div>
                                    <div className="mt-4">
                                        <table className="table mb-0">
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <span className="badge badge-primary">Network 1</span>
                                                    </td>
                                                    <td>Network 1</td>
                                                    <td className="text-right">54.5%</td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <span className="badge badge-success">Network 2</span>
                                                    </td>
                                                    <td>Network 2</td>
                                                    <td className="text-right">28.0%</td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <span className="badge badge-warning">Network 3</span>
                                                    </td>
                                                    <td>Network 3</td>
                                                    <td className="text-right">17.5%</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>*/}

                    <Row>
                        <Col xl={6}>
                            <Card>
                                <CardBody>
                                    <h4 className="card-title mb-4">Networks Income</h4>

                                    {this.state.affiliates.map((affiliate) => (
                                        <div key={affiliate.username} className="mb-30">
                                            <h4 className="small font-weight-bold">{affiliate.username}<span className="float-right">0.00</span></h4>
                                            <Progress color="primary" value={25}></Progress>
                                        </div>
                                    ))}
                                </CardBody>
                            </Card>
                        </Col>
                        <Col xl={6}>
                            <Card>
                                <CardBody>
                                    <h4 className="card-title mb-4">Providers Income</h4>

                                    {this.state.providers.map((provider) => (
                                        <div key={provider.id} className="mb-30">
                                            <h4 className="small font-weight-bold">{this.authAccount.type === 0 ? provider.vendor.name + " " + provider.name : provider.name}<span className="float-right">0.00</span></h4>
                                            <Progress color="primary" value={25}></Progress>
                                        </div>
                                    ))}
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </React.Fragment>
        );
    }
}

export default Dashboard;