import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Card, CardBody } from "reactstrap";




class InfoCard extends Component {

    constructor(props) {

        super(props);
    }


    render() {
        return (
            <Card className="mini-stat bg-primary text-white">
                <CardBody>
                    <div className="mb-4">
                        <div className="float-left mini-stat-img mr-4">
                            {this.props.icon} {/* <img src={servicesIcon1} alt="" />*/}
                        </div>
                        <h5 className="font-size-16 text-uppercase mt-0 text-white-50">
                            {this.props.title}
                        </h5>
                        <h4 className="font-weight-medium font-size-24">
                            {this.props.value + " "}
                            {this.props.arrow && this.props.arrow == "UP" ? <i className="mdi mdi-arrow-up text-success ml-2"></i> : <i className="mdi mdi-arrow-down text-danger ml-2"></i>}
                        </h4>
                        {this.props.badge != null ?
                            <div className={"mini-stat-label bg-" + this.props.badge.color}>
                                <p className="mb-0">{this.props.badge.value}</p>
                            </div> : null}
                    </div>
                    <div className="pt-2">
                        <div className="float-right">
                            <Link to="#" className="text-white-50">
                                <i className="mdi mdi-arrow-right h5"></i>
                            </Link>
                        </div>
                        <p className="text-white-50 mb-0 mt-1">Since last month</p>
                    </div>
                </CardBody>
            </Card>);
    }
}

export default InfoCard;