import React, { Component } from "react";
import { Row, Col, Card, CardBody, Alert } from "reactstrap";

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation";

import Loader from "../../components/Loader";
// action
import { registerUser, loginUser } from "../../store/actions";

// Redux
import { connect } from "react-redux";
import { Link } from "react-router-dom";

// import images
import logoSm from "../../assets/images/logo-sm.png";




class Register extends Component {

    constructor(props) {

        super(props);
        this.state = {};

        // handleValidSubmit
        this.handleValidSubmit = this.handleValidSubmit.bind(this);
    }



    // handleValidSubmit
    handleValidSubmit(event, values) {
        this.props.loginUser(
            { email: "admin@themesbrand.com", password: "123456" },
            this.props.history
        );
        // this.props.registerUser(values);
    }



    render() {
        return (
            <React.Fragment>
                <div className="account-pages my-5 pt-5">
                    <div className="container">
                        <Row className="justify-content-center">
                            <Col md={8} lg={6} xl={5}>
                                <div className="position-relative">
                                    {this.props.loading ? <Loader /> : null}

                                    <Card className="overflow-hidden">
                                        <div className="bg-primary">
                                            <div className="text-primary text-center p-4">
                                                <h5 className="text-white font-size-20">
                                                    Free Register
                                                </h5>
                                                <p className="text-white-50">
                                                    Get your free Veltrix account now.
                                                </p>
                                                <Link to="/" className="logo logo-admin">
                                                    <img src={logoSm} height="24" alt="logo" />
                                                </Link>
                                            </div>
                                        </div>
                                        <CardBody className="p-4">
                                            <div className="p-3">
                                                <AvForm className="form-horizontal mt-4" onValidSubmit={this.handleValidSubmit}>
                                                    {this.props.user && this.props.user ? (
                                                        <Alert color="success">
                                                            Register User Successfully
                                                        </Alert>
                                                    ) : null}
                                                    {this.props.registrationError &&
                                                        this.props.registrationError ? (
                                                            <Alert color="danger">
                                                                {this.props.registrationError}
                                                            </Alert>
                                                        ) : null}

                                                    <div className="form-group">
                                                        <AvField
                                                            name="email"
                                                            label="Email"
                                                            className="form-control"
                                                            placeholder="Enter email"
                                                            type="email"
                                                            required
                                                        />
                                                    </div>
                                                    <div className="form-group">
                                                        <AvField
                                                            name="username"
                                                            label="Username"
                                                            type="text"
                                                            required
                                                            placeholder="Enter username"
                                                        />
                                                    </div>
                                                    <div className="form-group">
                                                        <AvField
                                                            name="password"
                                                            label="Password"
                                                            type="password"
                                                            required
                                                            placeholder="Enter Password"
                                                        />
                                                    </div>
                                                    <Row className="form-group">
                                                        <div className="col-12 text-right">
                                                            <button className="btn btn-primary w-md waves-effect waves-light" type="submit">
                                                                Register
                                                            </button>
                                                        </div>
                                                    </Row>
                                                    <Row className="form-group mt-2 mb-0">
                                                        <div className="col-12 mt-4">
                                                            <p className="mb-0">
                                                                By registering you agree to the Veltrix{" "}<Link to="#" className="text-primary">Terms of Use</Link>
                                                            </p>
                                                        </div>
                                                    </Row>
                                                </AvForm>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </div>
                                <div className="mt-5 text-center">
                                    <p>
                                        Already have an account ?{" "}<Link to="pages-login" className="font-weight-medium text-primary">{" "}Login{" "}</Link>{" "}
                                    </p>
                                    <p className="mb-0">
                                        © {new Date().getFullYear()} RiseUP Games
                                    </p>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}



const mapStatetoProps = state => {
  const { user, registrationError, loading } = state.Account;
  return { user, registrationError, loading };
};


export default connect(mapStatetoProps, { registerUser, loginUser })(Register);