import React, { Component } from "react";
import { Card, CardBody, Modal } from "reactstrap";

import InputForm from "./InputForm";



class VendorModal extends Component {

    constructor(props) {

        super(props);
    }



    render() {

        let { title, close, save, vendor, handleChange, disabled } = this.props;

        return (
            <Modal isOpen={true} toggle={close} className="modal-lg">
                <div className="modal-header">
                    <h5 className="modal-title mt-0" id="myModalLabel">
                        {title}
                    </h5>
                    <button type="button" onClick={close} className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    <div className="col-12">
                        <Card>
                            <CardBody>
                                <InputForm name="name" type="text" label="Name" placeholder="Vendor Name" value={vendor} text="Min. 3 - Max. 50 chars" handleChange={(e) => handleChange(e)} />
                                <InputForm name="price" type="number" label="Price" placeholder="0.00" value={vendor} handleChange={(e) => handleChange(e)} />
                                <InputForm name="cost" type="number" label="Cost" placeholder="0.00" value={vendor} handleChange={(e) => handleChange(e)} />
                                <InputForm name="tax" type="number" label="TAX" placeholder="0.00" value={vendor} handleChange={(e) => handleChange(e)} />
                            </CardBody>
                        </Card>
                    </div>
                </div>
                <div className="modal-footer">
                    <button type="button" onClick={close} className="btn btn-secondary waves-effect" data-dismiss="modal">
                        Close
                    </button>
                    <button type="button" className="btn btn-primary waves-effect waves-light" onClick={save} disabled={disabled} >
                        Save
                    </button>
                </div>
            </Modal>);
    }
}

export default VendorModal;