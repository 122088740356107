import React, { Component } from "react";
import { Row, Col, Button, Card, CardHeader, CardBody, FormGroup, Label } from "reactstrap";
import { MDBDataTable } from "mdbreact";

import { getBackend } from "../../helpers/Backend";


let Backend;



class Warnings extends Component {

    constructor(props) {

        super(props);


        this.state = {
            reseller: null,
            resellers: [],
            network: {},
            networks: [],
            operator: {},
            operators: [],
            provider: {},
            providers: [],
            providersDropDown: [],
            disabled: false,
            loading: false,
            alert: null,
            table: null,
            start: JSON.stringify(new Date()).split('T')[0].split('"')[1] + "T00:00:00",
            end: JSON.stringify(new Date()).split('T')[0].split('"')[1] + "T23:59:59",
            userid: "",
            games: []
        };


        Backend = getBackend();

        this.authAccount = Backend.getAuthenticatedUser();

        this.RenderTable = this.RenderTable.bind(this);
    }



    componentDidMount() {

        let list = [];

        if (this.authAccount.type === 0 && this.authAccount.id === 1)
            Backend.getProviders((data) => {

                for (let i = 0; i < data.length; i++)
                    if (list.indexOf(data[i].name) === -1)
                        list.push(data[i].name);

                this.setState({ providers: data, providersDropDown: list });
            });
        else {

            for (let i = 0; i < this.authAccount.providers.length; i++)
                if (list.indexOf(this.authAccount.providers[i].name) === -1)
                    list.push(this.authAccount.providers[i].name);

            this.setState({ providers: this.authAccount.providers, providersDropDown: list });
        }

        if (this.authAccount.type === 0)
            Backend.getAccounts("reseller", (data) => { this.setState({ resellers: data }); Backend.getAccounts("network", (data) => { this.setState({ networks: data }); Backend.getAccounts("operator", (data) => { this.setState({ operators: data }); }); }); });
        else if (this.authAccount.type === 1)
            Backend.getAccounts("network", (data) => { this.setState({ networks: data }); Backend.getAccounts("operator", (data) => { this.setState({ operators: data }); }); });
        else
            Backend.getAccounts("operator", (data) => { this.setState({ operators: data }); });
    }



    RenderTable(data) {

        console.log("Render");

        let self = this;
        let table = {
            columns: [
                {
                    label: "Date",
                    field: "date",
                    sort: "asc",
                    width: 150
                }, {
                    label: "ID",
                    field: "id",
                    width: 150
                }, {
                    label: "Error",
                    field: "error",
                    width: 150
                }, {
                    label: "URL",
                    field: "url",
                    width: 150
                }, {
                    label: "Request",
                    field: "request",
                    width: 150
                }],
            rows: []
        }


        if (this.authAccount.type === 0 && data[0].vendor !== this.state.provider)
            table.columns.splice(1, 0, {
                label: "Vendor",
                field: "vendor",
                width: 150
            });

        const dataTable = JSON.parse(JSON.stringify(data));
        const userid = this.state.userid;

        dataTable.forEach(function (item, index) {

            let date = new Date(item.date);

            item.date = date.toLocaleDateString() + " " + date.toLocaleTimeString();
            item.request = JSON.stringify(item.request);

            table.rows.push(item);

            if (index === data.length - 1)
                self.setState({ table: <MDBDataTable responsive striped bordered entries={100} order={["date", "asc"]} data={table} />, loading: false });
        });
    }



    GetResellerOperators(index) {

        let list = [];
        let self = this;

        if (index >= 0) {

            let reseller = this.state.resellers[index];

            Backend.getAccounts("operator", (data) => {

                data.forEach((operator, index) => {

                    if (operator.parent === reseller.username)
                        list.push(operator);

                    if (index === data.length - 1)
                        self.setState({ operators: list });
                });
            });
        }
        else
            Backend.getAccounts("operator", (data) => { this.setState({ operators: data }) });
    }



    GetResellerNetworks(index) {

        let list = [];
        let self = this;

        if (index >= 0) {

            let reseller = this.state.resellers[index];

            Backend.getAccounts("network", (data) => {

                data.forEach((network, index) => {

                    if (network.parent === reseller.username)
                        list.push(network);

                    if (index === data.length - 1)
                        self.setState({ networks: list });
                });
            });
        }
        else
            Backend.getAccounts("network", (data) => { this.setState({ networks: data }) });


        this.GetResellerOperators(index);
    }



    GetNetworkOperators(index) {

        let list = [];
        let self = this;

        if (index >= 0) {

            let network = this.state.networks[index];

            Backend.getAccounts("operator", (data) => {

                data.forEach((operator, index) => {

                    if (operator.parent === network.username)
                        list.push(operator);

                    if (index === data.length - 1)
                        self.setState({ operators: list });
                });
            });
        }
        else
            Backend.getAccounts("operator", (data) => { this.setState({ operators: data }) });
    }



    async GetWarnings() {

        let tableData = [];
        let operator = this.state.operator;


        if (operator != null && operator.id != null && operator.id > 0) {

            this.setState({ table: null, loading: true });

            for (let i = 0; i < this.state.providers.length; i++) {

                let provider = this.state.providers[i];

                if (provider.name === this.state.provider) {

                    Backend.getWarnings(provider.vendor.name, provider.name, operator.username, this.state.start, this.state.end, (data) => {

                        for (let i = 0; i < data.data.length; i++) {

                            let row = data.data[i];
                            row.vendor = provider.vendor.name;

                            tableData.push(row);

                            if (tableData.length > 0)
                                this.RenderTable(tableData.sort((a, b) => { return new Date(a.date).getTime() - new Date(b.date).getTime(); }));
                        }
                    });
                }
            }


            setTimeout(() => { this.setState({ loading: false }) }, 33333);
        }
    }



    render() {

        let { resellers, networks, operators, providersDropDown } = this.state;

        return (
            <React.Fragment>
                <div className="container-fluid">
                    <Row className="align-items-center">
                        <Col sm="6">
                            <div className="page-title-box">
                                <h4 className="font-size-18">Communications Warnings</h4>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg="12">
                            <Card>
                                <CardHeader>
                                    <FormGroup row>
                                        <Col>
                                            <Label htmlFor="select">Providers:</Label>
                                            <select defaultValue={providersDropDown.length === 1 ? "0" : ""} className="form-control" onChange={(e) => this.setState({ provider: this.state.providersDropDown[parseInt(e.target.value)] })} >
                                                {providersDropDown.length === 1 ?
                                                    <option key={0} value={"0"}>{providersDropDown[0]}</option> :
                                                    <option value={""}>Select Provider</option>}
                                                {providersDropDown.length !== 1 ?
                                                    providersDropDown.map((provider, index) => (
                                                        <option key={index} value={index}>{provider}</option>
                                                    )) : null}
                                            </select>
                                        </Col>
                                        {this.authAccount.type === 0 ?
                                            <Col>
                                                <Label htmlFor="select">Resellers:</Label>
                                                <select defaultValue={0} className="form-control" onChange={(e) => parseInt(e.target.value) >= 0 ? this.GetResellerNetworks(parseInt(e.target.value)) : this.setState({ reseller: null })}  >
                                                    <option value={-1}>All Resellers</option>
                                                    {resellers.map((reseller, index) => (
                                                        <option key={index} value={index}>{reseller.username}</option>
                                                    ))}
                                                </select>
                                            </Col> : null}
                                        {this.authAccount.type < 2 ?
                                            <Col>
                                                <Label htmlFor="select">Networks:</Label>
                                                <select defaultValue={0} className="form-control" onChange={(e) => parseInt(e.target.value) >= 0 ? this.GetNetworkOperators(parseInt(e.target.value)) : this.setState({ network: null })}  >
                                                    <option value={-1}>All Networks</option>
                                                    {networks.map((network, index) => (
                                                        <option key={index} value={index}>{network.username}</option>
                                                    ))}
                                                </select>
                                            </Col> : null}
                                        {this.authAccount.type < 3 ?
                                            <Col>
                                                <Label htmlFor="select">Operators:</Label>
                                                <select defaultValue={-1} className="form-control" onChange={(e) => parseInt(e.target.value) >= 0 ? this.setState({ operator: this.state.operators[parseInt(e.target.value)] }) : this.setState({ operator: null })} >
                                                    <option value={-1}>All Operators</option>
                                                    {operators.map((operator, index) => (
                                                        <option key={index} value={index}>{operator.username}</option>
                                                    ))}
                                                </select>
                                            </Col> : null}
                                    </FormGroup>
                                    <FormGroup row>
                                        <Col sm="4">
                                            <Label htmlFor="datetime-from">From:</Label>
                                            <input className="form-control" type="datetime-local" defaultValue={this.state.start} onChange={(e) => this.setState({ start: e.target.value })} />
                                        </Col>
                                        <Col sm="4">
                                            <Label htmlFor="datetime-to">To:</Label>
                                            <input className="form-control" type="datetime-local" defaultValue={this.state.end} onChange={(e) => this.setState({ end: e.target.value })} />
                                        </Col>
                                        <Col sm="4">
                                            <Label htmlFor="username">UserID:</Label>
                                            <input className="form-control" type="text" defaultValue="" onChange={(e) => this.setState({ userid: e.target.value })} />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Col sm="2">
                                            <Button color="primary" className="btn btn-primary waves-effect waves-light" onClick={() => this.GetWarnings()} ><i className="mdi mdi-card-search-outline"></i>{" "}Search</Button>
                                        </Col>
                                    </FormGroup>
                                </CardHeader>
                                <CardBody>
                                    {this.state.table || (this.state.loading ? <span>Loading Data...</span> : <span>No Data Available</span>)}
                                </CardBody>
                            </Card>
                        </Col >
                    </Row>
                </div>
            </React.Fragment >
        );
    }
}

export default Warnings; 