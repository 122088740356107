import React, { Component } from "react";
import { Row, Col, Button, Card, CardHeader, CardBody, FormGroup, Input, InputGroup, InputGroupAddon, Label } from "reactstrap";
import { MDBDataTable } from "mdbreact";
import SweetAlert from "react-bootstrap-sweetalert";

import ConfigurationModal from "../../components/ConfigurationModal";

import { getBackend } from "../../helpers/Backend";


let Backend;



class Configurations extends Component {

    constructor(props) {

        super(props);


        this.state = {
            modal: false,
            configuration: {},
            configurations: [],
            providers: [],
            disabled: true,
            loading: false,
            alert: null,
            table: null,
            filter: 0
        };


        Backend = getBackend();

        this.authAccount = Backend.getAuthenticatedUser();


        this.Add = this.Add.bind(this);
        this.Update = this.Update.bind(this);
        this.toggle = this.toggle.bind(this);
        this.SetValue = this.SetValue.bind(this);
        this.RenderTable = this.RenderTable.bind(this);
        this.HandleProvider = this.HandleProvider.bind(this);
        this.HandleNewParam = this.HandleNewParam.bind(this);
        this.FilterTableByProvider = this.FilterTableByProvider.bind(this);
    }



    componentDidMount() {

        this.authAccount = Backend.getAuthenticatedUser();

        Backend.getProviders((data) => { this.setState({ providers: data }) });
        Backend.getConfigurations((data) => { this.setState({ modal: false, loading: false, configurations: data }); this.RenderTable(data) });
    }



    RenderTable(data) {

        let self = this;
        let table = {
            columns: [
                {
                    label: "ID",
                    field: "id",
                    sort: "asc",
                    width: 150
                }, {
                    label: "Name",
                    field: "name",
                    sort: "asc",
                    width: 150
                }, {
                    label: "Vendor",
                    field: "vendorName",
                    sort: "asc",
                    width: 150
                }, {
                    label: "Provider",
                    field: "providerName",
                    width: 150
                }, {
                    label: "Currencies",
                    field: "currency",
                    width: 150
                }, {
                    label: "Action",
                    field: "action",
                    width: 150
                }],
            rows: []
        };

        const dataTable = JSON.parse(JSON.stringify(data));

        dataTable.forEach(function (item, index) {

            item.vendorName = item.provider.vendor.name;
            item.providerName = item.provider.name;
            item.currency = item.currency.toString();
            item.action = self.action(item.id);

            table.rows.push(item);

            if (index === data.length - 1)
                self.setState({ table: <MDBDataTable responsive striped bordered data={table} /> });
        });
    }



    action(index) {

        return (
            <Col>
                <Button block color="success" onClick={() => { this.OpenEditModal(index) }}>Edit</Button>
                <Button block color="warning" onClick={() => { this.OpenCopyModal(index) }}>Copy</Button>
                <Button block color="danger">Disable</Button>
            </Col>
        );
    }



    toggle() {
        this.setState(prevState => ({
            modal: !prevState.modal, disabled: false, alert: null
        }));
    }



    SetValue(value, target) {

        switch (target.type) {

            case "text":
            case "email":
            case "select":
            case "password":
            case "select-one":
                if (isNaN(target.value)) {
                    if (target.value.indexOf(',') === -1)
                        value[target.name] = target.value;
                    else
                        value[target.name] = target.value.split(',');
                }
                else
                    value[target.name] = parseInt(target.value);
                break;


            case "number":
                if (!isNaN(target.value))
                    value[target.name] = parseFloat(target.value);
                break;


            case "checkbox":
                value[target.name] = target.checked;
                break;


            default:
                break;
        }


        if (this.state.configuration.name != null && this.state.configuration.name.length >= 3)
            this.setState({ disabled: false });
        else
            this.setState({ disabled: true });

        return value;
    }



    HandleCurrency(target) {

        let configuration = this.state.configuration;

        let currencies = target.value.toUpperCase().split(',');
        let lastCurrency = currencies[currencies.length - 1];

        if (lastCurrency.length > 3) {

            target.value = target.value.slice(0, target.value.length - 1) + "," + target.value[target.value.length - 1];

            currencies = target.value.split(',');
        }

        configuration.currency = currencies;

        this.setState({ configuration: configuration });
    }



    HandleProvider(target) {

        let id = parseInt(target.value);
        let configuration = this.state.configuration;

        if (id != NaN) {

            this.state.providers.forEach((provider) => {

                if (provider.id == id) {

                    configuration.provider = provider;
                    this.setState({ configuration: configuration });
                }
            });
        }
    }



    HandleNewParam(newConfiguration) {

        this.setState({ configuration: newConfiguration });
    }



    Add() {

        let configuration = this.state.configuration;

        this.setState({ disabled: true });

        if (configuration.currency != null && !Array.isArray(configuration.currency))
            configuration.currency = this.state.configuration.currency.split(',');

        Backend.addConfiguration(configuration, (data) => this.setState({ alert: { status: data } }));
    }



    Update() {

        let configuration = this.state.configuration;

        this.setState({ disabled: true });

        if (configuration.currency != null && !Array.isArray(configuration.currency))
            configuration.currency = this.state.configuration.currency.split(',');

        Backend.updateConfiguration(configuration, (data) => this.setState({ alert: { status: data } }));
    }



    GetIndex(id) {

        for (let i = 0; i < this.state.configurations.length; i++)
            if (this.state.configurations[i].id === id)
                return i;

        return 0;
    }



    OpenAddModal() {

        this.setState({ configuration: {}, modal: true });
    }



    OpenEditModal(id) {

        let index = this.GetIndex(id);

        this.setState({ configuration: this.state.configurations[index], disabled: false, modal: true });
    }



    OpenCopyModal(id) {

        let index = this.GetIndex(id);

        let configuration = this.state.configurations[index];
        configuration.id = 0;

        this.setState({ configuration: configuration, disabled: false, modal: true });
    }



    FilterTableByProvider(value) {

        let self = this;
        let id = parseInt(value);

        if (id > 0) {

            let data = [];

            this.state.configurations.forEach((configuration, index) => {

                if (configuration.provider.id === id)
                    data.push(configuration);

                if (index >= self.state.configurations.length - 1)
                    self.RenderTable(data);
            });
        }
        else
            this.RenderTable(this.state.configurations);

        this.setState({ filter: id });
    }



    render() {

        let providers = this.state.providers;
        let configuration = this.state.configuration; 
        let update = configuration != null && configuration.id > 0;

        return (
            <React.Fragment>

                {this.state.modal ?
                    <ConfigurationModal
                        title={(update ? "Update " : "Add ") + "Configuration"}
                        providers={providers}
                        configuration={configuration}
                        handleChange={(e) => this.setState({ configuration: this.SetValue(configuration, e.target) })}
                        handleCurrency={(e) => this.HandleCurrency(e.target)}
                        handleProvider={(e) => this.HandleProvider(e.target)}
                        handleNewParam={(conf) => this.HandleNewParam(conf)}
                        close={() => this.toggle()}
                        save={() => { update ? this.Update() : this.Add() }}
                        disabled={this.state.disabled}
                    /> : null}


                {this.state.alert ? (
                    this.state.alert.status ?
                        <SweetAlert
                            style={{ color: "black" }}
                            title="Good job!"
                            success
                            confirmBtnBsStyle="success"
                            onConfirm={() => { this.toggle(); Backend.getConfigurations((data) => { this.setState({ modal: false, loading: false, configurations: data }); this.FilterTableByProvider(this.state.filter) }) }}
                        >
                            New Provider Created
                        </SweetAlert> :
                        <SweetAlert
                            style={{ color: "black" }}
                            title="Error!"
                            warning
                            confirmBtnBsStyle="success"
                            onConfirm={() => this.setState({ alert: null, disabled: false })}
                        >
                            Something went wrong!
                        </SweetAlert>) : null
                }


                <div className="container-fluid">
                    <Row className="align-items-center">
                        <Col sm="6">
                            <div className="page-title-box">
                                <h4 className="font-size-18">{"Configurations List"}</h4>
                            </div>
                        </Col>

                        <Col sm="6">
                            <div className="float-right d-none d-md-block">
                                <Button color="primary" className="btn btn-primary waves-effect waves-light" onClick={() => this.OpenAddModal()} ><i className="mdi mdi-account-multiple-plus-outline"></i>{" "}Add</Button>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg="12">
                            <Card>
                                <CardHeader>
                                    <FormGroup row>
                                        <Col sm="4">
                                            <Label htmlFor="select">Providers:</Label>
                                            <select defaultValue={this.state.filter != null ? this.state.filter : 0} className="form-control" onChange={(e) => this.FilterTableByProvider(e.target.value)} >
                                                <option value={0}>All Providers</option>
                                                {this.state.providers.map((provider, index) => (
                                                    <option key={index} value={provider.id}>{provider.vendor.name !== provider.name ? provider.vendor.name + " " + provider.name : provider.name}</option>
                                                ))}
                                            </select>
                                        </Col>
                                    </FormGroup>
                                </CardHeader>
                                <CardBody>
                                    {this.state.table || (this.state.loading ? <span>Loading Data...</span> : <span>No Data Available</span>)}
                                </CardBody>
                            </Card>
                        </Col >
                    </Row>
                </div>
            </React.Fragment>);
    }
}

export default Configurations;