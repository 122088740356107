import React, { Component } from "react";
import { Row, Col, Card, CardBody, Alert } from "reactstrap";

// Redux
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation";

// action
import { userForgetPassword } from "../../store/actions";

// import images
import logoSm from "../../assets/images/logo-sm.png";



class ForgetPasswordPage extends Component {

    constructor(props) {

        super(props);
        this.state = {};

        // handleValidSubmit
        this.handleValidSubmit = this.handleValidSubmit.bind(this);
    }



    // handleValidSubmit
    handleValidSubmit(event, values) {
        this.props.userForgetPassword(values, this.props.history);
    }



    render() {
        return (
            <React.Fragment>
                <div className="account-pages my-5 pt-5">
                    <div className="container">
                        <Row className="justify-content-center">
                            <Col md={8} lg={6} xl={5}>
                                <div className="position-relative">
                                    <Card className="overflow-hidden">
                                        <div className="bg-primary">
                                            <div className="text-primary text-center p-4">
                                                <h5 className="text-white font-size-20">
                                                    Forget Password
                                                </h5>
                                                <p className="text-white-50">
                                                    Enter your email below and we would send a link to
                                                    reset your password.
                                                </p>
                                            </div>
                                        </div>

                                        <CardBody className="p-4">
                                            <div className="p-3">
                                                {this.props.forgetError && this.props.forgetError ? (
                                                    <Alert color="danger" style={{ marginTop: "13px" }}>
                                                        {this.props.forgetError}
                                                    </Alert>
                                                ) : null}
                                                {this.props.forgetSuccess ? (
                                                    <Alert color="success" style={{ marginTop: "13px" }}>
                                                        {this.props.forgetSuccess}
                                                    </Alert>
                                                ) : null}

                                                <AvForm className="form-horizontal mt-4" onValidSubmit={this.handleValidSubmit}>
                                                    <div className="form-group">
                                                        <AvField
                                                            name="email"
                                                            label="Email"
                                                            className="form-control"
                                                            placeholder="Enter email"
                                                            type="email"
                                                            required
                                                        />
                                                    </div>
                                                    <Row className="form-group">
                                                        <Col className="text-right">
                                                            <button className="btn btn-primary w-md waves-effect waves-light" type="submit">Reset</button>
                                                        </Col>
                                                    </Row>
                                                </AvForm>
                                            </div>
                                        </CardBody>
                                    </Card>
                                    <div className="mt-5 text-center">
                                        <p>
                                            Go back to{" "}<Link to="pages-login" className="font-weight-medium text-primary">Login</Link>{" "}
                                        </p>
                                        <p className="mb-0">
                                            © {new Date().getFullYear()} RiseUP Games
                                        </p>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}



const mapStatetoProps = state => {
  console.log(state.forgetSuccessMsg);
  const { forgetError, forgetSuccessMsg } = state.ForgetPassword;
  return { forgetError, forgetSuccessMsg };
};



export default withRouter(
  connect(mapStatetoProps, { userForgetPassword })(ForgetPasswordPage)
);