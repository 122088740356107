import React from "react";
import { Route, Redirect } from "react-router-dom";

import { getBackend } from "../helpers/Backend";



const AppRoute = ({
    component: Component,
    layout: Layout,
    isAuthProtected,
    ...rest
}) => (
        <Route
            {...rest}
            render={props => {
                const Backend = getBackend();

                if (isAuthProtected && !Backend.getAuthenticatedUser()) {
                    return (
                        <Redirect to={{ pathname: "/login", state: { from: props.location } }} />
                    );
                }

                return (
                    <Layout>
                        <Component {...props} />
                    </Layout>
                );
            }}
        />
    );


export default AppRoute;