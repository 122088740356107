import React, { Component } from "react";
import { Row, Col, Button, Card, CardHeader, CardBody, Modal } from "reactstrap";
import { MDBDataTable } from "mdbreact";
import SweetAlert from "react-bootstrap-sweetalert";

import VendorModal from "../../components/VendorModal";

import { getBackend } from "../../helpers/Backend";


let Backend;



class Vendors extends Component {

    constructor(props) {

        super(props);


        this.state = {
            modal: false,
            vendor: {},
            vendors: [],
            disabled: true,
            loading: false,
            alert: null,
            table: null
        };


        Backend = getBackend();

        this.authAccount = Backend.getAuthenticatedUser();

        this.Add = this.Add.bind(this);
        this.toggle = this.toggle.bind(this);
        this.SetValue = this.SetValue.bind(this);
        this.RenderTable = this.RenderTable.bind(this);
    }



    componentDidMount() {

        this.authAccount = Backend.getAuthenticatedUser();

        this.setState({ loading: true });

        Backend.getVendors((data) => { this.setState({ modal: false, loading: false, vendors: data }); this.RenderTable(data) });
    }



    RenderTable(data) {

        let self = this;
        let table = {
            columns: [
                {
                    label: "ID",
                    field: "id",
                    sort: "asc",
                    width: 150
                }, {
                    label: "Name",
                    field: "name",
                    sort: "asc",
                    width: 150
                }, {
                    label: "Price",
                    field: "price",
                    sort: "asc",
                    width: 150
                }, {
                    label: "Cost",
                    field: "cost",
                    width: 150
                }, {
                    label: "TAX",
                    field: "tax",
                    width: 150
                }, {
                    label: "Action",
                    field: "action",
                    width: 150
                }],
            rows: []
        }

        const dataTable = JSON.parse(JSON.stringify(data));

        dataTable.forEach(function (item, index) {

            item.action = self.action(index);

            table.rows.push(item);

            if (index === data.length - 1)
                self.setState({ table: <MDBDataTable responsive striped bordered data={table} /> });
        });
    }



    action(index) {

        return (
            <Col>
                <Button block color="success" onClick={() => { this.OpenEditModal(index) }}>Edit</Button>
                <Button block color="warning" onClick={() => { this.OpenCopyModal(index) }}>Copy</Button>
                <Button block color="danger">Disable</Button>
            </Col>
        );
    }



    toggle() {
        this.setState(prevState => ({
            modal: !prevState.modal, disabled: false, alert: null
        }));
    }



    SetValue(value, target) {

        switch (target.type) {

            case "text":
            case "email":
            case "select":
            case "password":
            case "select-one":
                value[target.name] = target.value;
                break;


            case "number":
                if (!isNaN(target.value))
                    value[target.name] = parseFloat(target.value);
                break;


            case "checkbox":
                value[target.name] = target.checked;
                break;


            default:
                break;
        }


        if (this.state.vendor.name != null && this.state.vendor.name.length >= 3)
            this.setState({ disabled: false });
        else
            this.setState({ disabled: true });

        return value;
    }



    Add() {

        this.setState({ disabled: true });

        Backend.addVendor(this.state.vendor, (data) => this.setState({ alert: { status: data } }));
    }



    OpenAddModal() {

        this.setState({ vendor: {}, modal: true });
    }



    OpenEditModal(index) {

        this.setState({ vendor: this.state.vendors[index], modal: true });
    }



    OpenCopyModal(index) {

        let account = this.state.accounts[index];
        account.id = 0;

        this.setState({ account: account, addModal: true });
    }



    render() {

        let table = this.state.table;
        let vendor = this.state.vendor;
        let update = vendor != null && vendor.id > 0;


        return (
            <React.Fragment>

                {this.state.modal ?
                    <VendorModal
                        title={(update ? "Update " : "Add ") + "Vendor"}
                        vendor={vendor}
                        handleChange={(e) => this.setState({ vendor: this.SetValue(vendor, e.target) })}
                        close={() => this.toggle()}
                        save={() => { update ? this.Add() : this.Add() }}
                        disabled={this.state.disabled}
                    /> : null}


                {this.state.alert ? (
                    this.state.alert.status ?
                        <SweetAlert
                            style={{ color: "black" }}
                            title="Good job!"
                            success
                            confirmBtnBsStyle="success"
                            onConfirm={() => { this.toggle(); Backend.getVendors((data) => { this.setState({ modal: false, loading: true, vendors: data }); this.RenderTable(data) }) }}
                        >
                            New Vendor Created
                        </SweetAlert> :
                        <SweetAlert
                            style={{ color: "black" }}
                            title="Error!"
                            warning
                            confirmBtnBsStyle="success"
                            onConfirm={() => this.setState({ alert: null, disabled: false })}
                        >
                            Something went wrong!
                        </SweetAlert>) : null
                }


                <div className="container-fluid">
                    <Row className="align-items-center">
                        <Col sm="6">
                            <div className="page-title-box">
                                <h4 className="font-size-18">{"Vendors List"}</h4>
                            </div>
                        </Col>

                        <Col sm="6">
                            <div className="float-right d-none d-md-block">
                                <Button color="primary" className="btn btn-primary waves-effect waves-light" onClick={() => this.OpenAddModal()} ><i className="mdi mdi-account-multiple-plus-outline"></i>{" "}Add</Button>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg="12">
                            <Card>
                                <CardHeader>
                                </CardHeader>
                                <CardBody>
                                    {table || (this.state.loading ? <span>Loading Data...</span> : <span>No Data Available</span>)}
                                </CardBody>
                            </Card>
                        </Col >
                    </Row>
                </div>
            </React.Fragment>);
    }
}

export default Vendors;