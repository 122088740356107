import React from 'react';
import { Spinner } from 'reactstrap';


const Loader = (props) => {
    return (
        <div className="preloader">
            <div className="status">
                <Spinner color={props != null ? props.color || "success" : "success"} />
            </div>
        </div>
    );
};

export default Loader;