import React, { Component } from "react";
import { Row, Col, Card, CardBody, Alert } from "reactstrap";

// Redux
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation";

import Loader from "../../components/Loader";


import { getBackend } from "../../helpers/Backend";

import publicIp from "public-ip";


let Backend;




class Login extends Component {

    constructor(props) {

        super(props);

        this.state = {
            loading: false,
            disabled: false,
            error: null
        };


        Backend = getBackend();


        this.ip = "";


        this.handleError = this.handleError.bind(this);
        this.handleValidSubmit = this.handleValidSubmit.bind(this);
    }



    componentDidMount() {

        this.getClientIp().then(
            (data) => { this.ip = data }
        );
    }



    handleValidSubmit(event, values) {

        this.setState({ loading: true, disabled: true, error: null });

        Backend.loginUser(values.username, values.password, this.ip, this.handleError);
    }



    handleError(error) {

        this.setState({ loading: false, disabled: false, error: error.message });
    }



    getClientIp = async () => await publicIp.v4({
        fallbackUrls: ["https://ifconfig.co/ip"]
    });



    render() {
        return (
            <React.Fragment>
                <div className="account-pages my-5 pt-5">
                    <div className="container">
                        <Row className="justify-content-center">
                            <Col md={8} lg={6} xl={5}>
                                <div className="position-relative">
                                    <Card className="overflow-hidden">
                                        <div className="bg-primary">
                                            <div className="text-primary text-center p-4">
                                                <h5 className="text-white font-size-20">
                                                    Welcome Back !
                                                </h5>
                                                <p className="text-white-50">
                                                    Sign in to continue to RiseUP Games HUB
                                                </p>
                                                {/*<Link to="/" className="logo logo-admin">
                                                    <img src={logoSm} height="24" alt="logo" />
                                                </Link>*/}
                                            </div>
                                        </div>

                                        <CardBody className="p-4">
                                            <div className="p-3">
                                                <AvForm className="form-horizontal mt-4" onValidSubmit={this.handleValidSubmit}>
                                                    {this.state.error ? (
                                                        <Alert color="danger">{this.state.error}</Alert>
                                                    ) : null}

                                                    <div className="form-group">
                                                        <AvField
                                                            name="username"
                                                            label="Username"
                                                            className="form-control"
                                                            value=""
                                                            placeholder="Enter Username"
                                                            type="text"
                                                            required
                                                        />
                                                    </div>
                                                    <div className="form-group">
                                                        <AvField
                                                            name="password"
                                                            label="Password"
                                                            type="password"
                                                            required
                                                            value=""
                                                            placeholder="Enter Password"
                                                        />
                                                    </div>

                                                    <Row className="form-group">
                                                        <Col sm={6}>
                                                            &nbsp;
                                                        {/* <div className="custom-control custom-checkbox">
                                                            <input type="checkbox" className="custom-control-input" id="customControlInline" />
                                                            <label className="custom-control-label" for="customControlInline">Remember me</label>
                                                        </div> */}
                                                        </Col>
                                                        <Col sm={6} className="text-right">
                                                            <button className="btn btn-primary w-md waves-effect waves-light" type="submit" disabled={this.state.disabled} >{this.state.loading ? <Loader color="info" /> : null}Log In</button>
                                                        </Col>
                                                    </Row>
                                                    <Row className="form-group mt-2 mb-0">
                                                        <div className="col-12 mt-4">
                                                            <Link to="/forget-password">
                                                                <i className="mdi mdi-lock"></i> Forgot your password?
                                                            </Link>
                                                        </div>
                                                    </Row>
                                                </AvForm>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </div>
                                <div className="mt-5 text-center">
                                    {/*<p>
                                        Don't have an account ?{" "}
                                        <Link to="pages-register" className="font-weight-medium text-primary">
                                            {" "}Signup now{" "}
                                        </Link>{" "}
                                    </p>*/}
                                    <p className="mb-0">
                                        © {new Date().getFullYear()} RiseUP Games
                                    </p>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}


export default withRouter(Login);