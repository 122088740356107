import React, { Component } from "react";
import { Row, Col, Button, Card, CardHeader, CardBody, FormGroup, Label } from "reactstrap";
import { MDBDataTable } from "mdbreact";
import SweetAlert from "react-bootstrap-sweetalert";

import ProviderModal from "../../components/ProviderModal";

import { getBackend } from "../../helpers/Backend";


let Backend;



class Providers extends Component {

    constructor(props) {

        super(props);


        this.state = {
            modal: false,
            provider: {},
            providers: [],
            vendors: [],
            disabled: true,
            loading: false,
            alert: null,
            table: null,
            filter: 0
        };


        Backend = getBackend();
        this.authAccount = Backend.getAuthenticatedUser();


        this.Add = this.Add.bind(this);
        this.Update = this.Update.bind(this);
        this.toggle = this.toggle.bind(this);
        this.SetValue = this.SetValue.bind(this);
        this.RenderTable = this.RenderTable.bind(this);
        this.HandleVendor = this.HandleVendor.bind(this);
    }



    componentDidMount() {

        this.authAccount = Backend.getAuthenticatedUser();

        this.setState({ loading: true });

        Backend.getVendors((data) => { this.setState({ vendors: data }) });
        Backend.getProviders((data) => { this.setState({ modal: false, loading: false, providers: data }); this.RenderTable(data) });
    }



    RenderTable(data) {

        let self = this;
        let table = {
            columns: [
                {
                    label: "ID",
                    field: "id",
                    sort: "asc",
                    width: 150
                }, {
                    label: "Name",
                    field: "name",
                    sort: "asc",
                    width: 150
                }, {
                    label: "Vendor",
                    field: "vendorName",
                    sort: "asc",
                    width: 150
                }, {
                    label: "Price",
                    field: "price",
                    sort: "asc",
                    width: 150
                }, {
                    label: "Cost",
                    field: "cost",
                    width: 150
                }, {
                    label: "TAX",
                    field: "tax",
                    width: 150
                }, {
                    label: "Action",
                    field: "action",
                    width: 150
                }],
            rows: []
        }

        const dataTable = JSON.parse(JSON.stringify(data));

        dataTable.forEach(function (item, index) {

            item.vendorName = item.vendor.name;
            item.action = self.action(item.id);

            table.rows.push(item);

            if (index === data.length - 1)
                self.setState({ table: <MDBDataTable responsive striped bordered entries={20} order={ [ "id", "asc" ] } data={table} /> });
        });
    }



    action(index) {

        return (
            <Col>
                <Button block color="success" onClick={() => { this.OpenEditModal(index) }}>Edit</Button>
                <Button block color="warning" onClick={() => { this.OpenCopyModal(index) }}>Copy</Button>
                <Button block color="danger">Disable</Button>
            </Col>
        );
    }



    toggle() {
        this.setState(prevState => ({
            modal: !prevState.modal, disabled: false, alert: null
        }));
    }



    SetValue(value, target) {

        switch (target.type) {

            case "text":
            case "email":
            case "select":
            case "password":
            case "select-one":
                value[target.name] = target.value;
                break;


            case "number":
                if (!isNaN(target.value))
                    value[target.name] = parseFloat(target.value);
                break;


            case "checkbox":
                value[target.name] = target.checked;
                break;


            default:
                break;
        }


        if (this.state.provider.name != null && this.state.provider.name.length >= 3)
            this.setState({ disabled: false });
        else
            this.setState({ disabled: true });

        return value;
    }



    HandleVendor(target) {

        let id = parseInt(target.value);
        let provider = this.state.provider;

        if (id != NaN) {

            this.state.vendors.forEach((vendor) => {

                if (vendor.id == id) {

                    provider.vendor = vendor;
                    this.setState({ provider: provider });
                }
            });
        }
    }



    Add() {

        this.setState({ disabled: true });

        Backend.addProvider(this.state.provider, (data) => this.setState({ alert: { status: data } }));
    }



    Update() {

        this.setState({ disabled: true });

        Backend.updateProvider(this.state.provider, (data) => this.setState({ alert: { status: data } }));
    }



    GetIndex(id) {

        for (let i = 0; i < this.state.providers.length; i++)
            if (this.state.providers[i].id === id)
                return i;

        return 0;
    }



    OpenAddModal() {

        this.setState({ provider: {}, modal: true });
    }



    OpenEditModal(id) {

        let index = this.GetIndex(id);

        this.setState({ provider: this.state.providers[index], modal: true });
    }



    OpenCopyModal(id) {

        let index = this.GetIndex(id);

        let provider = this.state.providers[index];
        provider.id = 0;

        this.setState({ provider: provider, modal: true });
    }



    FilterTableByVendor(value) {

        let self = this;
        let id = parseInt(value);

        if (id > 0) {

            let data = [];

            this.state.providers.forEach((provider, index) => {

                if (provider.vendor.id === id)
                    data.push(provider);

                if (index === self.state.providers.length - 1)
                    self.RenderTable(data);
            });
        }
        else
            this.RenderTable(this.state.providers);

        this.setState({ filter: id });
    }



    render() {

        let vendors = this.state.vendors;
        let provider = this.state.provider;
        let update = provider != null && provider.id > 0;

        return (
            <React.Fragment>

                {this.state.modal ?
                    <ProviderModal
                        title={(update ? "Update " : "Add ") + "Provider"}
                        vendors={vendors}
                        provider={provider}
                        handleChange={(e) => this.setState({ provider: this.SetValue(provider, e.target) })}
                        handleVendor={(e) => this.HandleVendor(e.target)}
                        close={() => this.toggle()}
                        save={() => { update ? this.Update() : this.Add() }}
                        disabled={this.state.disabled}
                    /> : null}


                {this.state.alert ? (
                    this.state.alert.status ?
                        <SweetAlert
                            style={{ color: "black" }}
                            title="Good job!"
                            success
                            confirmBtnBsStyle="success"
                            onConfirm={() => { this.toggle(); Backend.getProviders((data) => { this.setState({ modal: false, loading: false, providers: data }); this.FilterTableByVendor(this.state.filter) }) }}
                        >
                            New Provider Created
                        </SweetAlert> :
                        <SweetAlert
                            style={{ color: "black" }}
                            title="Error!"
                            warning
                            confirmBtnBsStyle="success"
                            onConfirm={() => this.setState({ alert: null, disabled: false })}
                        >
                            Something went wrong!
                        </SweetAlert>) : null
                }


                <div className="container-fluid">
                    <Row className="align-items-center">
                        <Col sm="6">
                            <div className="page-title-box">
                                <h4 className="font-size-18">{"Providers List"}</h4>
                            </div>
                        </Col>

                        <Col sm="6">
                            <div className="float-right d-none d-md-block">
                                <Button color="primary" className="btn btn-primary waves-effect waves-light" onClick={() => this.OpenAddModal()} ><i className="mdi mdi-account-multiple-plus-outline"></i>{" "}Add</Button>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg="12">
                            <Card>
                                <CardHeader>
                                    <FormGroup row>
                                        <Col sm="4">
                                            <Label htmlFor="select">Vendors:</Label>
                                            <select defaultValue={this.state.filter != null ? this.state.filter : 0} className="form-control" onChange={(e) => this.FilterTableByVendor(e.target.value)} >
                                                <option value={0}>All Vendors</option>
                                                {this.state.vendors.map((vendor, index) => (
                                                    <option key={index} value={vendor.id}>{vendor.name}</option>
                                                ))}
                                            </select>
                                        </Col>
                                    </FormGroup>
                                </CardHeader>
                                <CardBody>
                                    {this.state.table || (this.state.loading ? <span>Loading Data...</span> : <span>No Data Available</span>)}
                                </CardBody>
                            </Card>
                        </Col >
                    </Row>
                </div>
            </React.Fragment>);
    }
}

export default Providers;