import firebase from "firebase/app";
// Add the Firebase products that you want to use
import "firebase/auth";
import "firebase/firestore";

let config;


class Backend {

    constructor(Config) {

        if (Config) {

            config = Config;
        }
    }



    getAuthenticatedUser = () => {

        if (!sessionStorage.getItem("authUser")) return null;
        return JSON.parse(sessionStorage.getItem("authUser"));
    };



    _handleError(error) {

        // var errorCode = error.code;
        var errorMessage = error.message;
        return errorMessage;
    }



    async SendRequest(type, token, method, request) {

        return new Promise(async function (resolve, reject) {

            let response = {};


            if (type == "GET")
                response = await fetch(config.apiUrl + "/" + (token ? token + "/" : "") + method, {
                    method: type,
                    mode: "cors",
                    headers: {
                        "Accept": "application/json",
                        "Content-Type": "application/json",
                        "Access-Control-Allow-Origin": "*"
                    }
                });
            else
                response = await fetch(config.apiUrl + "/" + (token ? token + "/" : "") + method, {
                    method: type,
                    mode: "cors",
                    headers: {
                        "Accept": "application/json",
                        "Content-Type": "application/json",
                        "Access-Control-Allow-Origin": "*"
                    },
                    body: request !== null ? JSON.stringify(request) : {}
                });


            if (response.status == 200) {

                const data = await response.json();

                resolve(data);
            }
            else if (response.status == 401) {

                reject({ message: "Session Expired!" });
            }
            else
                reject({ message: "Status: " + response.status });
        });
    }



    loginUser = (username, password, ip, onError) => {

        this.SendRequest("POST", null, "login", { username: username, password: password, ip: ip }).then(
            (data) => {

                if (data !== null) {

                    if (data.id > 0) {

                        sessionStorage.setItem("authUser", JSON.stringify(data));
                        window.location.href = "/dashboard";
                    }
                    else
                        onError({ message: "Username or Password Wrong" });
                }
                else
                    onError({ message: "Generic Error" });
            },
            (error) => { onError(error) }
        );
    };



    registerUser = (email, password) => {
        return new Promise((resolve, reject) => {
            firebase
                .auth()
                .createUserWithEmailAndPassword(email, password)
                .then(
                    user => {
                        resolve(firebase.auth().currentUser);
                    },
                    error => {
                        reject(this._handleError(error));
                    }
                );
        });
    };



    forgetPassword = email => {
        return new Promise((resolve, reject) => {
            firebase
                .auth()
                .sendPasswordResetEmail(email, {
                    url: window.location.protocol + "//" + window.location.host + "/login"
                })
                .then(() => {
                    console.log("yes authutils");
                    resolve(true);
                })
                .catch(error => {
                    reject(this._handleError(error));
                });
        });
    };



    logout = () => {

        sessionStorage.removeItem("authUser");
        return true;
    };



    addReseller = (account, handleOK) => {

        this.SendRequest("POST", this.getAuthenticatedUser().token, "accounts/reseller", account).then(
            (data) => { handleOK(data) },
            (error) => { this._handleError(error) }
        );
    };



    addNetwork = (account, handleOK) => {

        this.SendRequest("POST", this.getAuthenticatedUser().token, "accounts/network", account).then(
            (data) => { handleOK(data) },
            (error) => { this._handleError(error) }
        );
    };



    addOperator = (account, handleOK) => {

        this.SendRequest("POST", this.getAuthenticatedUser().token, "accounts/operator", account).then(
            (data) => { handleOK(data) },
            (error) => { this._handleError(error) }
        );
    };



    addSupport = (account, handleOK) => {

        this.SendRequest("POST", this.getAuthenticatedUser().token, "accounts/support", account).then(
            (data) => { handleOK(data) },
            (error) => { this._handleError(error) }
        );
    };



    updateAccount = (username, account, handleOK) => {

        this.SendRequest("PUT", this.getAuthenticatedUser().token, "accounts/" + username, account).then(
            (data) => { handleOK(data) },
            (error) => { this._handleError(error) }
        );
    };



    getAccounts = (type, handleOK) => {

        this.SendRequest("GET", this.getAuthenticatedUser().token, "accounts/" + type, null).then(
            (data) => { handleOK(data) },
            (error) => { this._handleError(error) }
        );
    };



    getOwners = (type, handleOK) => {

        this.SendRequest("GET", this.getAuthenticatedUser().token, "accounts/owners/" + type, null).then(
            (data) => { handleOK(data) },
            (error) => { this._handleError(error) }
        );
    };



    getPlayers = (vendor, provider, operator, handleOK) => {

        this.SendRequest("GET", this.getAuthenticatedUser().token, "accounts/player/" + vendor + "/" + provider + (operator ? "?operatorID=" + operator : ""), null).then(
            (data) => { handleOK(data) },
            (error) => { this._handleError(error) }
        );
    };



    getOnlinePlayers = (handleOK) => {

        this.SendRequest("GET", this.getAuthenticatedUser().token, "accounts/player/online", null).then(
            (data) => { handleOK(data) },
            (error) => { this._handleError(error) }
        );
    };





    addVendor = (vendor, handleOK) => {

        this.SendRequest("POST", this.getAuthenticatedUser().token, "providers/vendor", vendor).then(
            (data) => { handleOK(data) },
            (error) => { this._handleError(error) }
        );
    };


    getVendors = (handleOK) => {

        this.SendRequest("GET", this.getAuthenticatedUser().token, "providers/vendors", null).then(
            (data) => { handleOK(data) },
            (error) => { this._handleError(error) }
        );
    };



    addProvider = (provider, handleOK) => {

        this.SendRequest("POST", this.getAuthenticatedUser().token, "providers", provider).then(
            (data) => { handleOK(data) },
            (error) => { this._handleError(error) }
        );
    };



    getProviders = (handleOK) => {

        this.SendRequest("GET", this.getAuthenticatedUser().token, "providers", null).then(
            (data) => { handleOK(data) },
            (error) => { this._handleError(error) }
        );
    };



    getReports = (vendor, provider, operator, start, end, handleOK, handleError) => {

        this.SendRequest("GET", this.getAuthenticatedUser().token, vendor + "/" + provider + "/reports" + (operator != null ? "/" + operator.username : "") + "?start=" + start + "&end=" + end, null).then(
            (data) => { handleOK(data) },
            (error) => { handleError(error) }
        );
    };



    getPlays = (vendor, provider, operator, start, end, handleOK) => {

        this.SendRequest("GET", this.getAuthenticatedUser().token, vendor + "/" + provider + "/plays/" + operator + "?start=" + start + "&end=" + end, null).then(
            (data) => { handleOK(data) },
            (error) => { this._handleError(error) }
        );
    };



    getRollbacks = (provider, operator, start, end, handleOK) => {

        this.SendRequest("GET", this.getAuthenticatedUser().token, provider + "/rollbacks/" + operator + "?start=" + start + "&end=" + end, null).then(
            (data) => { handleOK(data) },
            (error) => { this._handleError(error) }
        );
    };



    getWarnings = (vendor, provider, operator, start, end, handleOK) => {

        this.SendRequest("GET", this.getAuthenticatedUser().token, vendor + "/" + provider + "/warnings/" + operator + "?start=" + start + "&end=" + end, null).then(
            (data) => { handleOK(data) },
            (error) => { this._handleError(error) }
        );
    };



    updateProvider = (provider, handleOK) => {

        this.SendRequest("PUT", this.getAuthenticatedUser().token, "providers", provider).then(
            (data) => { handleOK(data) },
            (error) => { this._handleError(error) }
        );
    };



    addConfiguration = (configuration, handleOK) => {

        this.SendRequest("POST", this.getAuthenticatedUser().token, "providers/configurations", configuration).then(
            (data) => { handleOK(data) },
            (error) => { this._handleError(error) }
        );
    };


    getConfigurations = (handleOK) => {

        this.SendRequest("GET", this.getAuthenticatedUser().token, "providers/configurations", null).then(
            (data) => { handleOK(data) },
            (error) => { this._handleError(error) }
        );
    };


    updateConfiguration = (configuration, handleOK) => {

        this.SendRequest("PUT", this.getAuthenticatedUser().token, "providers/configurations", configuration).then(
            (data) => { handleOK(data) },
            (error) => { this._handleError(error) }
        );
    };



    getCurrencyRatio = (currency, handleOK) => {

        this.SendRequest("GET", this.getAuthenticatedUser().token, "currency/" + currency + "/ratio", currency).then(
            (data) => { handleOK(data) },
            (error) => { this._handleError(error) }
        );
    };



    getStatsTotal = (handleOK) => {

        this.SendRequest("GET", this.getAuthenticatedUser().token, "stats/total", null).then(
            (data) => { handleOK(data) },
            (error) => { this._handleError(error) }
        );
    }
}



let _Backend = null;


const initBackend = config => {

    if (!_Backend) {

        _Backend = new Backend(config);
    }

    return _Backend;
};


const getBackend = () => {

    return _Backend;
};


export { initBackend, getBackend };