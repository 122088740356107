import React, { Component } from "react";
import { Row, Col, Card, CardBody } from "reactstrap";




class InvoiceModal extends Component {

    constructor(props) {

        super(props);
    }



    render() {

        let { customer, date, data, interval, close, disabled } = this.props;

        let totFinac = 0;

        for (let i = 0; i < data.length; i++) {

            if (data[i].provider.toLowerCase() != "texinho")
                totFinac += (data[i].financial > 0 ? data[i].financial : 0);
            /*else
                totFinac += data[i].financial;*/
        }

        return (
            <React.Fragment>
                <div className="container-fluid">
                    <Row className="align-items-center">
                        <Col sm="6">
                            <div className="page-title-box">
                                <h4 className="font-size-18">Invoice</h4>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <div className="col-12">
                            <Card>
                                <CardBody>
                                    <Row>
                                        <div className="col-12">
                                            <div className="invoice-title">
                                                <h4 className="float-right font-size-16">
                                                    <strong>Summary # {Date.now()}</strong>
                                                </h4>
                                                <h3 className="mt-0">
                                                    <span className="logo" style={{ color: "#FF6565" }}>RISE<span style={{ color: "#FF0000" }} >UP</span></span>
                                                </h3>
                                            </div>
                                            <hr />
                                            <Row>
                                                <div className="col-6">
                                                    <address>
                                                        <strong>Billed To:</strong>
                                                        <br />
                                                        {customer}
                                                    </address>
                                                </div>
                                                <div className="col-6 text-right">
                                                    <address>
                                                        <strong>Shipped To:</strong>
                                                        <br />
                                                        {customer}
                                                    </address>
                                                </div>
                                            </Row>
                                            <Row>
                                                <div className="col-6 mt-4">
                                                    <address>
                                                        <strong>Payment Method:</strong>
                                                    </address>
                                                </div>
                                                <div className="col-6 mt-4 text-right">
                                                    <address>
                                                        <strong>Summary Date:</strong>
                                                        <br />
                                                        {date}
                                                        <br />
                                                        <br />
                                                    </address>
                                                </div>
                                            </Row>
                                        </div>
                                    </Row>

                                    <Row>
                                        <div className="col-12">
                                            <div>
                                                <div className="p-2">
                                                    <h4>
                                                        Casino Summary<h6>{" period " + interval}</h6>
                                                    </h4>
                                                </div>
                                                <div className="">
                                                    <div className="table-responsive">
                                                        <table className="table">
                                                            <thead>
                                                                <tr>
                                                                    <td>
                                                                        <h5>Provider</h5>
                                                                    </td>
                                                                    <td className="text-center">
                                                                        <h5>Bet</h5>
                                                                    </td>
                                                                    <td className="text-center">
                                                                        <h5>Win</h5>
                                                                    </td>
                                                                    <td className="text-center">
                                                                        <h5>GGR</h5>
                                                                    </td>
                                                                    <td className="text-center">
                                                                        <h5>Price</h5>
                                                                    </td>
                                                                    <td className="text-right">
                                                                        <h5>Financial</h5>
                                                                    </td>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    data.map((row) => (
                                                                        row.provider.toLowerCase() != "texinho" ?
                                                                            <tr>
                                                                                <td>{row.provider}</td>
                                                                                <td className="text-center">{row.bet.toFixed(2)}</td>
                                                                                <td className="text-center">{row.win.toFixed(2)}</td>
                                                                                <td className="text-center">{(row.bet - row.win).toFixed(2)}</td>
                                                                                <td className="text-center">{row.fee ? row.fee.toFixed(2) + "%" : ""}</td>
                                                                                <td className="text-right">{row.financial ? row.financial.toFixed(2) : "0.00"}</td>
                                                                            </tr> : null
                                                                    ))
                                                                }
                                                                {/*<tr>
                                                                    <td className="no-line"></td>
                                                                    <td className="no-line"></td>
                                                                    <td className="no-line"></td>
                                                                    <td className="no-line"></td>
                                                                    <td className="no-line text-left">
                                                                        <h4>Total Commission:</h4>
                                                                    </td>
                                                                    <td className="no-line text-right"><h5 className="m-0">{totComm.toFixed(2) + "€"}</h5></td>
                                                                </tr>*/}
                                                                <tr>
                                                                    <td className="no-line"></td>
                                                                    <td className="no-line"></td>
                                                                    <td className="no-line"></td>
                                                                    <td className="no-line"></td>
                                                                    <td className="no-line text-left">
                                                                        <h4>Total Financial:</h4>
                                                                    </td>
                                                                    <td className="no-line text-right">
                                                                        <h4 className="m-0">{totFinac.toFixed(2) + "€"}</h4>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>

                                                    <div className="d-print-none">
                                                        <div className="float-right">
                                                            <button type="button" onClick={close} className="btn btn-secondary waves-effect" >
                                                                Close
                                                            </button>
                                                            {" "}
                                                            <button type="button" className="btn btn-primary waves-effect waves-light" onClick={() => { window.print(); }} disabled={disabled} >
                                                                <i className="fa fa-print"></i> Print
                                                            </button>
                                                        </div>
                                                    </div>

                                                    {/*<div className="p-2">
                                                        <h3 className="font-size-16">
                                                            <strong>Poker Summary</strong>
                                                        </h3>
                                                    </div>
                                                    <div className="">
                                                        <div className="table-responsive">
                                                            <table className="table">
                                                                <thead>
                                                                    <tr>
                                                                        <td>
                                                                            <strong>Provider</strong>
                                                                        </td>
                                                                        <td className="text-center">
                                                                            <strong>Bet</strong>
                                                                        </td>
                                                                        <td className="text-center">
                                                                            <strong>Win</strong>
                                                                        </td>
                                                                        <td className="text-center">
                                                                            <strong>Rake</strong>
                                                                        </td>
                                                                        <td className="text-center">
                                                                            <strong>Price</strong>
                                                                        </td>
                                                                        <td className="text-center">
                                                                            <strong>Commission</strong>
                                                                        </td>
                                                                        <td className="text-right">
                                                                            <strong>Financial</strong>
                                                                        </td>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {
                                                                        data.map((row) => (
                                                                            row.provider.toLowerCase() == "texinho" ?
                                                                                <tr>
                                                                                    <td>{row.provider}</td>
                                                                                    <td className="text-center">{row.bet.toFixed(2)}</td>
                                                                                    <td className="text-center">{row.win.toFixed(2)}</td>
                                                                                    <td className="text-center">{row.extra.toFixed(2)}</td>
                                                                                    <td className="text-center">{(row.financial * 100 / (row.bet - row.win)).toFixed(2) + "%"}</td>
                                                                                    <td className="text-center">{row.financial.toFixed(2)}</td>
                                                                                    <td className="text-right">{row.financial.toFixed(2)}</td>
                                                                                </tr> : null
                                                                        ))
                                                                    }
                                                                    <tr>
                                                                        <td className="no-line"></td>
                                                                        <td className="no-line"></td>
                                                                        <td className="no-line"></td>
                                                                        <td className="no-line"></td>
                                                                        <td className="no-line"></td>
                                                                        <td className="no-line text-left">
                                                                            <strong>Total Commission</strong>
                                                                        </td>
                                                                        <td className="no-line text-right">{totComm.toFixed(2) + "€"}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="no-line"></td>
                                                                        <td className="no-line"></td>
                                                                        <td className="no-line"></td>
                                                                        <td className="no-line"></td>
                                                                        <td className="no-line"></td>
                                                                        <td className="no-line text-left">
                                                                            <strong>Total Financial</strong>
                                                                        </td>
                                                                        <td className="no-line text-right">
                                                                            <h4 className="m-0">{totFinac.toFixed(2) + "€"}</h4>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>

                                                        <div className="d-print-none">
                                                            <div className="float-right">
                                                                <button type="button" onClick={close} className="btn btn-secondary waves-effect" >
                                                                    Close
                                                                </button>
                                                                {" "}
                                                                <button type="button" className="btn btn-primary waves-effect waves-light" onClick={() => { window.print(); }} disabled={disabled} >
                                                                    <i className="fa fa-print"></i> Print
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>*/}
                                                </div>
                                            </div>
                                        </div>
                                    </Row>
                                </CardBody>
                            </Card>
                        </div>
                    </Row>
                </div>
            </React.Fragment>
        );
    }
}

export default InvoiceModal;
