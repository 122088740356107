import React, { Component } from "react";
import { Row, Col, Card, CardBody, Modal, FormGroup, Input, InputGroup, InputGroupAddon, Label, Button } from "reactstrap";

import InputForm from "./InputForm";



class ConfigurationModal extends Component {

    constructor(props) {

        super(props);

        this.state = {
            paramName: "",
            paramValue: ""
        };

        this.AddFormGroup = this.AddFormGroup.bind(this);
    }



    componentDidMount() {

    }



    AddFormGroup() {

        let params = [];
        let configuration = this.props.configuration;

        configuration[this.state.paramName] = this.state.paramValue;

        if (configuration != null)
            for (let value in configuration)
                if (value != "id" && value != "name" && value != "provider" && value != "currency" && value != "vendorName" && value != "providerName" && value != "action")
                    params.push({ name: value, value: configuration[value] });

        this.props.handleNewParam(configuration);

        this.setState({ paramName: "", paramValue: "" });
    }



    render() {

        let { title, close, save, configuration, providers, handleChange, handleCurrency, handleProvider, disabled } = this.props;

        let params = [];

        if (configuration != null)
            for (let value in configuration)
                if (value != "id" && value != "name" && value != "provider" && value != "currency" && value != "vendorName" && value != "providerName" && value != "action")
                    params.push({ name: value, value: configuration[value] });

        return (
            <Modal isOpen={true} toggle={close} className="modal-lg">
                <div className="modal-header">
                    <h5 className="modal-title mt-0" id="myModalLabel">
                        {title}
                    </h5>
                    <button type="button" onClick={close} className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    <div className="col-12">
                        <Card>
                            <CardBody>
                                <InputForm name="name" type="text" label="Name" placeholder="Configuration Name" value={configuration} text="Min. 3 - Max. 50 chars" handleChange={(e) => handleChange(e)} />
                                <FormGroup row>
                                    <label className="col-sm-2 col-form-label">Provider</label>
                                    <Col sm={10}>
                                        <select defaultValue={configuration.provider != null ? configuration.provider.id : 0} className="form-control" onChange={(e) => handleProvider(e)} >
                                            <option value={0}>Not Set</option>
                                            {providers.map((provider, index) => (
                                                <option key={index} value={provider.id}>{provider.vendor.name !== provider.name ? provider.vendor.name + " " + provider.name : provider.name}</option> 
                                            ))}
                                        </select>
                                    </Col>
                                </FormGroup>
                                <InputForm name="currency" type="text" label="Currency" placeholder="EUR,USD,GBP" value={configuration} text="ISO 4217 Currency Code (3 chars)" handleChange={(e) => handleCurrency(e)} />
                                {params.map((param, index) =>
                                    <InputForm key={index} name={param.name} type="text" label={param.name} placeholder={"Configuration " + param.name} value={configuration} handleChange={(e) => handleChange(e)} />
                                )}
                                <FormGroup row>
                                    <Col sm="2">
                                        <Label htmlFor="select">New Param</Label>
                                    </Col>
                                    <Col xs="12" sm="10">
                                        <InputGroup>
                                            <Input type="text" name="paramName" placeholder="Name" value={this.state.paramName || ""} onChange={e => this.setState({ paramName: e.target.value })} />
                                            <Input type="text" name="paramValue" placeholder="Value" value={this.state.paramValue || ""} onChange={e => this.setState({ paramValue: (e.target.value.indexOf(',') === -1 ? e.target.value : e.target.value.split(',')) })} />
                                            <InputGroupAddon addonType="append">
                                                <Button type="button" color="primary" onClick={() => this.AddFormGroup()}>Add</Button>
                                            </InputGroupAddon>
                                        </InputGroup>
                                    </Col>
                                </FormGroup>
                            </CardBody>
                        </Card>
                    </div>
                </div>
                <div className="modal-footer">
                    <button type="button" onClick={close} className="btn btn-secondary waves-effect" data-dismiss="modal">
                        Close
                    </button>
                    <button type="button" className="btn btn-primary waves-effect waves-light" onClick={save} disabled={disabled} >
                        Save
                    </button>
                </div>
            </Modal>);
    }
}

export default ConfigurationModal;