import React, { Component } from "react";
import { Row, Col, Card, CardBody, Modal } from "reactstrap";

import InputForm from "./InputForm";



class ProviderModal extends Component {

    constructor(props) {

        super(props);
    }



    render() {

        let { title, close, save, vendors, provider, handleChange, handleVendor, disabled } = this.props;

        return (
            <Modal isOpen={true} toggle={close} className="modal-lg">
                <div className="modal-header">
                    <h5 className="modal-title mt-0" id="myModalLabel">
                        {title}
                    </h5>
                    <button type="button" onClick={close} className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    <div className="col-12">
                        <Card>
                            <CardBody>
                                <InputForm name="name" type="text" label="Name" placeholder="Provider Name" value={provider} text="Min. 3 - Max. 50 chars" handleChange={(e) => handleChange(e)} />
                                <Row className="form-group">
                                    <label className="col-sm-2 col-form-label">Vendor</label>
                                    <Col sm={10}>
                                        <select defaultValue={provider.vendor != null ? provider.vendor.id : 0} className="form-control" onChange={(e) => handleVendor(e)} >
                                            <option value={0}>Not Set</option>
                                            {vendors.map((vendor, index) => (
                                                <option key={index} value={vendor.id}>{vendor.name}</option>
                                            ))}
                                        </select>
                                    </Col>
                                </Row>
                                <InputForm name="price" type="number" label="Price" placeholder="0.00" value={provider} handleChange={(e) => handleChange(e)} />
                                <InputForm name="cost" type="number" label="Cost" placeholder="0.00" value={provider} handleChange={(e) => handleChange(e)} />
                                <InputForm name="tax" type="number" label="TAX" placeholder="0.00" value={provider} handleChange={(e) => handleChange(e)} />
                            </CardBody>
                        </Card>
                    </div>
                </div>
                <div className="modal-footer">
                    <button type="button" onClick={close} className="btn btn-secondary waves-effect" data-dismiss="modal">
                        Close
                    </button>
                    <button type="button" className="btn btn-primary waves-effect waves-light" onClick={save} disabled={disabled} >
                        Save
                    </button>
                </div>
            </Modal>);
    }
}

export default ProviderModal;