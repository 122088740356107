import React, { Component } from 'react';
import ChartistGraph from 'react-chartist';




class lineareachart extends Component {

    constructor(props) {

        super(props);

        this.props = props;
    }



    render() {

        const lineChartOptions = {
            low: 0,
            showArea: true,
            axisY: {
                offset: 10,
                onlyInteger: true
            }
        }


        return (
            <React.Fragment>
                <ChartistGraph data={this.props.data} style={{ height: "290px" }} options={lineChartOptions} type={this.props.type || "Line"} />
            </React.Fragment>
        );
    }
}

export default lineareachart;