import React, { Component } from "react";
import { Row, Col, Button, Card, CardHeader, CardBody, FormGroup, Label, CardFooter } from "reactstrap";
import { MDBDataTable } from "mdbreact";

import { getBackend } from "../../helpers/Backend";


let Backend;



class GamesPlayed extends Component {

    constructor(props) {

        super(props);


        this.state = {
            reseller: null,
            resellers: [],
            network: {},
            networks: [],
            operator: {},
            operators: [],
            provider: {},
            providers: [],
            providersDropDown: [],
            disabled: false,
            loading: false,
            alert: null,
            table: null,
            start: JSON.stringify(new Date()).split('T')[0].split('"')[1] + "T00:00:00",
            end: JSON.stringify(new Date()).split('T')[0].split('"')[1] + "T23:59:59",
            userid: "",
            currency: "",
            games: [],
            totbet: 0,
            totwin: 0,
            tableData: {}
        };


        Backend = getBackend();

        this.authAccount = Backend.getAuthenticatedUser();

        this.RenderTable = this.RenderTable.bind(this);
    }



    componentDidMount() {

        let list = [];

        if (this.authAccount.type === 0 && this.authAccount.id === 1)
            Backend.getProviders((data) => {

                for (let i = 0; i < data.length; i++)
                    if (list.indexOf(data[i].name) === -1)
                        list.push(data[i].name);

                if (data != null && data.length == 1)
                    this.setState({ provider: list[0], providers: data, providersDropDown: list });
                else
                    this.setState({ providers: data, providersDropDown: list });
            });
        else {

            for (let i = 0; i < this.authAccount.providers.length; i++)
                if (list.indexOf(this.authAccount.providers[i].name) === -1)
                    list.push(this.authAccount.providers[i].name);

            if (this.authAccount.providers != null && this.authAccount.providers.length == 1)
                this.setState({ provider: list[0], providers: this.authAccount.providers, providersDropDown: list });
            else
                this.setState({ providers: this.authAccount.providers, providersDropDown: list });
        }


        if (this.authAccount.type === 0)
            Backend.getAccounts("reseller", (data) => { this.setState({ resellers: data }); Backend.getAccounts("network", (data) => { this.setState({ networks: data }); Backend.getAccounts("operator", (data) => { this.setState({ operators: data }); }); }); });
        else if (this.authAccount.type === 1)
            Backend.getAccounts("network", (data) => { this.setState({ networks: data }); Backend.getAccounts("operator", (data) => { this.setState({ operators: data }); }); });
        else
            Backend.getAccounts("operator", (data) => { this.setState({ operators: data }); });
    }



    GetGameImage(games, id) {

        let image = null;

        if (games != null)
            games.forEach(function (game) {

                if (image == null && game.id == id)
                    image = game.imgUrl;
            });

        return image;
    }



    RenderTable(data) {

        let self = this;
        let tBet = 0;
        let tWin = 0;
        let tExtra = 0;
        let table = {
            rows: []
        };

        const dataTable = JSON.parse(JSON.stringify(data));
        const { userid, currency } = this.state;

        dataTable.forEach(function (item, index) {

            if ((userid == null || userid.length === 0 || userid === item.user.pid || userid === item.user.name) && (currency == null || currency.length === 0 || currency === item.currency)) {

                let date = new Date(item.date);

                item.date = date.toLocaleDateString() + " " + date.toLocaleTimeString();
                item.uid = item.user.id;
                item.pid = item.user.pid;
                item.username = item.user.name;

                item.balance = item.user.balance.toFixed(2);

                if (item.reason !== "REFUND") {

                    item.before = (item.user.balance + item.bet - item.win).toFixed(2);
                    tBet += item.bet;
                }
                else {

                    item.before = (item.user.balance - item.bet + item.win).toFixed(2);
                    tBet -= item.bet;
                }

                tWin += item.win;
                tExtra += item.extra;

                item.bet = item.bet.toFixed(2);
                item.win = item.win.toFixed(2);
                item.extra = item.extra.toFixed(2);

                let image = self.GetGameImage(self.state.games, item.gameID);
                item.gameID = image != null ? <center><img alt={item.gameID} src={image} style={{ maxWidth:"200px" }} /></center> : item.gameID;

                table.rows.push(item);
            }

            if (index === data.length - 1) {

                table.columns = [
                    {
                        label: "Date",
                        field: "date",
                        sort: "asc",
                        width: 150
                    }, {
                        label: "Internal ID",
                        field: "uid",
                        width: 150
                    }, {
                        label: "Partner ID",
                        field: "pid",
                        width: 150
                    }, {
                        label: "Username",
                        field: "username",
                        width: 150
                    }, {
                        label: "Game",
                        field: "gameID",
                        width: 100
                    }, {
                        label: "Round ID",
                        field: "roundID",
                        width: 150
                    }, {
                        label: "Transaction ID",
                        field: "transactionID",
                        sort: "asc",
                        width: 150
                    }, {
                        label: "Reference ID",
                        field: "id",
                        sort: "asc",
                        width: 150
                    }, {
                        label: "Type",
                        field: "reason",
                        width: 150
                    }, {
                        label: "Balance Before",
                        field: "before",
                        width: 150
                    }, {
                        label: "Bet",
                        field: "bet",
                        width: 150
                    }, {
                        label: "Win",
                        field: "win",
                        width: 150
                    }, {
                        label: "Balance After",
                        field: "balance",
                        width: 150
                    }, {
                        label: "Currency",
                        field: "currency",
                        width: 150
                    }];


                if (self.authAccount.type === 0 && table.rows[0].vendor !== self.state.provider)
                    table.columns.splice(4, 0, {
                        label: "Vendor",
                        field: "vendor",
                        width: 150
                    });


                if (tExtra > 0)
                    table.columns.splice(table.columns.length - 2, 0, {
                        label: "Rake",
                        field: "extra",
                        width: 150
                    });


                self.setState({ table: <MDBDataTable responsive striped bordered entries={100} order={["date", "asc"]} data={table} />, totbet: tBet, totwin: tWin, tableData: table, loading: false });
            }
        });
    }



    GetResellerOperators(index) {

        let list = [];
        let self = this;

        if (index >= 0) {

            let reseller = this.state.resellers[index];

            Backend.getAccounts("operator", (data) => {

                data.forEach((operator, index) => {

                    if (operator.parent === reseller.username)
                        list.push(operator);

                    if (index === data.length - 1)
                        self.setState({ operators: list });
                });
            });
        }
        else
            Backend.getAccounts("operator", (data) => { this.setState({ operators: data }) });
    }



    GetResellerNetworks(index) {

        let list = [];
        let self = this;

        if (index >= 0) {

            let reseller = this.state.resellers[index];

            Backend.getAccounts("network", (data) => {

                data.forEach((network, index) => {

                    if (network.parent === reseller.username)
                        list.push(network);

                    if (index === data.length - 1)
                        self.setState({ networks: list });
                });
            });
        }
        else
            Backend.getAccounts("network", (data) => { this.setState({ networks: data }) });


        this.GetResellerOperators(index);
    }



    GetNetworkOperators(index) {

        let list = [];
        let self = this;

        if (index >= 0) {

            let network = this.state.networks[index];

            Backend.getAccounts("operator", (data) => {

                data.forEach((operator, index) => {

                    if (operator.parent === network.username)
                        list.push(operator);

                    if (index === data.length - 1)
                        self.setState({ operators: list });
                });
            });
        }
        else
            Backend.getAccounts("operator", (data) => { this.setState({ operators: data }) });
    }



    async GetGames() {

        let games = [];
        this.setState({ table: null, loading: true, totbet: 0, totwin: 0 });

        for (let i = 0; i < this.state.providers.length; i++) {

            let provider = this.state.providers[i];

            if (provider.name === this.state.provider) {

                let url = "";

                if (provider.name !== provider.vendor.name)
                    url = "https://api.riseupgames.net/" + provider.vendor.name + "/" + provider.name + "/games/RiseUPDemo";
                else
                    url = "https://api.riseupgames.net/" + provider.name + "/games/RiseUPDemo";


                let response = await fetch(url, {
                    method: "GET",
                    mode: "cors",
                    headers: {
                        "Accept": "application/json",
                        "Content-Type": "application/json",
                        "Access-Control-Allow-Origin": "*"
                    }
                });


                if (response.status == 200) {

                    const data = await response.json();

                    if (data.status)
                        for (let j = 0; j < data.data.length; j++)
                            games.push(data.data[j]);
                }
            }
        }


        this.setState({ games: games });
        this.GetGamesPlayed();
    }



    async GetGamesPlayed() {

        let tableData = [];
        let operator = this.state.operator;


        if (operator != null && operator.id != null && operator.id > 0) {

            this.setState({ table: null, loading: true });

            for (let i = 0; i < this.state.providers.length; i++) {

                let provider = this.state.providers[i];

                if (provider.name === this.state.provider) {

                    Backend.getPlays(provider.vendor.name, provider.name, operator.username, this.state.start, this.state.end, (data) => {

                        for (let i = 0; i < data.data.length; i++) {

                            let row = data.data[i];
                            row.vendor = provider.vendor.name;

                            tableData.push(row);
                        }

                        if (tableData.length > 0)
                            this.RenderTable(tableData.sort((a, b) => { return new Date(a.date).getTime() - new Date(b.date).getTime(); }));
                    });
                }
            }


            setTimeout(() => { this.setState({ loading: false }) }, 33333);
        }
    }



    DownloadJsonData(JSONData, FileTitle, ShowLabel) {

        var CSV = '';
        var arrData = typeof JSONData != 'object' ? JSON.parse(JSONData) : JSONData;


        if (ShowLabel) {

            var row = "";

            for (var index in arrData[0])
                row += index.toUpperCase() + ',';

            row = row.slice(0, -1);
            CSV += row + '\r\n';
        }


        for (var i = 0; i < arrData.length; i++) {

            var row = "";

            for (var index in arrData[i])
                row += '"' + arrData[i][index] + '",';

            row.slice(0, row.length - 1);
            CSV += row + '\r\n';
        }


        if (CSV == '') {

            alert("Invalid data");
            return;
        }


        var filename = FileTitle + " " + (new Date());
        var blob = new Blob([CSV], {
            type: 'text/csv;charset=utf-8;'
        });

        if (navigator.msSaveBlob) { // IE 10+
            navigator.msSaveBlob(blob, filename);
        } else {
            var link = document.createElement("a");
            if (link.download !== undefined) { // feature detection
                // Browsers that support HTML5 download attribute
                var url = URL.createObjectURL(blob);
                link.setAttribute("href", url);
                link.style = "visibility:hidden";
                link.download = filename + ".csv";
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }
    }



    render() {

        let { resellers, networks, operators, providersDropDown } = this.state;

        return (
            <React.Fragment>
                <div className="container-fluid">
                    <Row className="align-items-center">
                        <Col sm="6">
                            <div className="page-title-box">
                                <h4 className="font-size-18">Games Played List</h4>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg="12">
                            <Card>
                                <CardHeader>
                                    <FormGroup row>
                                        <Col>
                                            <Label htmlFor="select">Providers:</Label>
                                            <select defaultValue={providersDropDown.length === 1 ? "0" : ""} className="form-control" onChange={(e) => this.setState({ provider: this.state.providersDropDown[parseInt(e.target.value)] })} >
                                                {providersDropDown.length === 1 ?
                                                    <option key={0} value={"0"}>{providersDropDown[0]}</option> :
                                                    <option value={""}>Select Provider</option>}
                                                {providersDropDown.length !== 1 ?
                                                    providersDropDown.map((provider, index) => (
                                                        <option key={index} value={index}>{provider}</option>
                                                    )) : null}
                                            </select>
                                        </Col>
                                        {this.authAccount.type === 0 ?
                                            <Col>
                                                <Label htmlFor="select">Resellers:</Label>
                                                <select defaultValue={0} className="form-control" onChange={(e) => parseInt(e.target.value) >= 0 ? this.GetResellerNetworks(parseInt(e.target.value)) : this.setState({ reseller: null })}  >
                                                    <option value={-1}>All Resellers</option>
                                                    {resellers.map((reseller, index) => (
                                                        <option key={index} value={index}>{reseller.username}</option>
                                                    ))}
                                                </select>
                                            </Col> : null}
                                        {this.authAccount.type < 2 ?
                                            <Col>
                                                <Label htmlFor="select">Networks:</Label>
                                                <select defaultValue={0} className="form-control" onChange={(e) => parseInt(e.target.value) >= 0 ? this.GetNetworkOperators(parseInt(e.target.value)) : this.setState({ network: null })}  >
                                                    <option value={-1}>All Networks</option>
                                                    {networks.map((network, index) => (
                                                        <option key={index} value={index}>{network.username}</option>
                                                    ))}
                                                </select>
                                            </Col> : null}
                                        {this.authAccount.type < 3 ?
                                            <Col>
                                                <Label htmlFor="select">Operators:</Label>
                                                <select defaultValue={-1} className="form-control" onChange={(e) => parseInt(e.target.value) >= 0 ? this.setState({ operator: this.state.operators[parseInt(e.target.value)] }) : this.setState({ operator: null })} >
                                                    <option value={-1}>All Operators</option>
                                                    {operators.map((operator, index) => (
                                                        <option key={index} value={index}>{operator.username}</option>
                                                    ))}
                                                </select>
                                            </Col> : null}
                                    </FormGroup>
                                    <FormGroup row>
                                        <Col sm="3">
                                            <Label htmlFor="datetime-from">From:</Label>
                                            <input className="form-control" type="datetime-local" defaultValue={this.state.start} onChange={(e) => this.setState({ start: e.target.value })} />
                                        </Col>
                                        <Col sm="3">
                                            <Label htmlFor="datetime-to">To:</Label>
                                            <input className="form-control" type="datetime-local" defaultValue={this.state.end} onChange={(e) => this.setState({ end: e.target.value })} />
                                        </Col>
                                        <Col sm="3">
                                            <Label htmlFor="username">Username \ Partner ID:</Label>
                                            <input className="form-control" type="text" defaultValue="" onChange={(e) => this.setState({ userid: e.target.value })} />
                                        </Col>
                                        <Col sm="3">
                                            <Label htmlFor="currency">Currency:</Label>
                                            <input className="form-control" type="text" defaultValue="" onChange={(e) => this.setState({ currency: e.target.value.toUpperCase() })} />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Col sm="2">
                                            <Button color="primary" className="btn btn-primary waves-effect waves-light" disabled={this.state.loading} onClick={() => this.GetGames()} ><i className="mdi mdi-card-search-outline"></i>{" "}Search</Button>
                                        </Col>
                                        <Col sm="8" />
                                        <Col sm="2">
                                            <div className="float-right d-none d-md-block">
                                                <Button color="primary" className="btn btn-primary waves-effect waves-light" onClick={() => this.state.provider && this.state.tableData.rows != null ? this.DownloadJsonData(this.state.tableData.rows, this.state.provider.name, true) : null} ><i className="mdi mdi-download"></i>{" "}Download</Button>
                                            </div>
                                        </Col>
                                    </FormGroup>

                                </CardHeader>
                                <CardBody>
                                    {this.state.table || (this.state.loading ? <span>Loading Data...</span> : <span>No Data Available</span>)}
                                </CardBody>
                                <CardFooter>
                                    {
                                        (this.state.totbet > 0 || this.state.totwin > 0) ?
                                            <Row>
                                                <Col sm="4">
                                                    <Label>{"Total BET: " + this.state.totbet.toFixed(2)}</Label>
                                                </Col>
                                                <Col sm="4">
                                                    <Label>{"Total WIN: " + this.state.totwin.toFixed(2)}</Label>
                                                </Col>
                                                <Col sm="4">
                                                    <Label>{"Total NET: " + (this.state.totbet - this.state.totwin).toFixed(2)}</Label>
                                                </Col>
                                            </Row> : null
                                    }
                                </CardFooter>
                            </Card>
                        </Col >
                    </Row>
                </div>
            </React.Fragment >
        );
    }
}

export default GamesPlayed; 