import React, { Component } from "react";
import { Row, Col, Button, Card, CardHeader, CardBody, FormGroup, Label, CardFooter } from "reactstrap";
import { MDBDataTable } from "mdbreact";

import InvoiceModal from "../../components/InvoiceModal";

import { getBackend } from "../../helpers/Backend";


let Backend;




class AccountReports extends Component {

    constructor(props) {

        super(props);


        this.state = {
            reseller: null,
            resellers: [],
            network: null,
            networks: [],
            operator: null,
            operators: [],
            provider: null,
            providers: [],
            providersDropDown: [],
            affiliate: [],
            disabled: true,
            loading: false,
            alert: null,
            table: [],
            start: JSON.stringify(new Date()).split('T')[0].split('"')[1] + "T00:00:00",
            end: JSON.stringify(new Date()).split('T')[0].split('"')[1] + "T23:59:59",
            invoiceModal: false,
            customer: "",
            date: "",
            invoice: null
        };


        Backend = getBackend();

        this.authAccount = Backend.getAuthenticatedUser();
        this.reports = [];


        this.RenderTable = this.RenderTable.bind(this);
    }



    componentDidMount() {

        let list = [];

        //if (this.authAccount.type === 0 && this.authAccount.id === 1)
            Backend.getProviders((data) => {

                for (let i = 0; i < data.length; i++)
                    if (list.indexOf(data[i].name) === -1)
                        list.push(data[i].name);

                this.setState({ providers: data, providersDropDown: list });
            });
        /*else {

            for (let i = 0; i < this.authAccount.providers.length; i++)
                if (list.indexOf(this.authAccount.providers[i].name) === -1)
                    list.push(this.authAccount.providers[i].name);

            this.setState({ providers: this.authAccount.providers, providersDropDown: list });
        }*/


        if (this.authAccount.type === 0)
            Backend.getAccounts("reseller", (data) => { this.setState({ resellers: data }); Backend.getAccounts("network", (data) => { this.setState({ networks: data }); Backend.getAccounts("operator", (data) => { this.setState({ operators: data }); this.GetAffiliates(); }); }); });
        else if (this.authAccount.type === 1)
            Backend.getAccounts("network", (data) => { this.setState({ networks: data }); Backend.getAccounts("operator", (data) => { this.setState({ operators: data }); this.GetAffiliates(); }); });
        else
            Backend.getAccounts("operator", (data) => { this.setState({ operators: data }); this.GetAffiliates(); });


        let start = new Date();
        let end = new Date();

        switch (this.props.range) {

            case "daily":
                start.setDate(start.getDate() - 1);
                end.setDate(end.getDate() - 1);
                break;


            case "weekly":
                start.setDate(start.getDate() - 7);
                end.setDate(end.getDate() - 1);
                break;


            case "monthly":
                start.setDate(start.getDate() - 1);
                start = new Date(start.getFullYear(), start.getMonth(), 1);
                start.setDate(start.getDate() + 1);
                end.setDate(end.getDate() - 1);
                break;


            default:
                return;
        }


        this.setState({
            start: JSON.stringify(start).split('T')[0].split('"')[1] + "T00:00:00",
            end: JSON.stringify(end).split('T')[0].split('"')[1] + "T23:59:59",
        });
    }



    GetAffiliates() {

        let list = [];
        let affiliates = [];


        for (let i = 0; i < this.state.resellers.length; i++) {

            list = [];
            let reseller = this.state.resellers[i];

            for (let j = 0; j < this.state.networks.length; j++) {

                let network = this.state.networks[j];

                if (network.parent === reseller.username) {

                    for (let k = 0; k < this.state.operators.length; k++) {

                        let operator = this.state.operators[k];

                        if (operator.parent === network.username)
                            list.push(operator);
                    }
                }
            }

            for (let k = 0; k < this.state.operators.length; k++) {

                let operator = this.state.operators[k];

                if (operator.parent === reseller.username)
                    list.push(operator);
            }

            if (list.length > 0)
                affiliates.push({ username: reseller.username, providers: reseller.providers, operators: list });
        }


        for (let j = 0; j < this.state.networks.length; j++) {

            let network = this.state.networks[j];

            if (network.parent === this.authAccount.username) {

                list = [];

                for (let k = 0; k < this.state.operators.length; k++) {

                    let operator = this.state.operators[k];

                    if (operator.parent === network.username)
                        list.push(operator);
                }

                if (list.length > 0)
                    affiliates.push({ username: network.username, providers: network.providers, operators: list });
            }
        }


        list = [];

        for (let k = 0; k < this.state.operators.length; k++) {

            let operator = this.state.operators[k];

            if (operator.parent === this.authAccount.username)
                list.push(operator);
        }

        if (list.length > 0)
            affiliates.push({ username: this.authAccount.username, providers: this.authAccount.providers, operators: list });


        affiliates = affiliates.sort((a, b) => {
            return (a.username.toUpperCase() < b.username.toUpperCase()) ? -1 : (a.username.toUpperCase() > b.username.toUpperCase()) ? 1 : 0;
        });

        this.setState({ affiliates: affiliates, disabled: false });
    }



    RenderTable(reports) {

        let self = this;
        let currencies = JSON.parse(sessionStorage.getItem("currencies"));

        if (this.state.operator == null) {

            this.state.affiliates.forEach((affiliate) => {

                let tBet = 0;
                let tWin = 0;
                let tExtra = 0;
                let tComm = 0;
                let dataSet = new Array();

                if (affiliate.username !== this.authAccount.username) {

                    for (let i = 0; i < reports.length; i++) {

                        let report = reports[i];

                        for (let j = 0; j < currencies.length; j++) {

                            let currency = currencies[j];

                            let singleSet = {};

                            singleSet.vendor = report.provider.vendor.name;
                            singleSet.provider = report.provider.name;


                            let bet = 0;
                            let win = 0;
                            let extra = 0;
                            let rate = currency.rate;

                            for (let z = 0; z < affiliate.operators.length; z++) {

                                let operator = affiliate.operators[z];

                                for (let k = 0; k < report.reports.length; k++) {

                                    let data = report.reports[k];

                                    if (data.operator.username === operator.username && data.report.currency === currency.currency) {

                                        bet += data.report.bet;
                                        win += data.report.win;
                                        extra += data.report.extra;
                                    }
                                }
                            }


                            let net = 0;
                            let commission = 0;
                            let financial = 0;
                            let percFee = 0;

                            if (affiliate.parent !== this.authAccount.username)
                                percFee = affiliate.providers.filter(p => p.vendor.name.toLowerCase() === report.provider.vendor.name.toLowerCase() && p.name.toLowerCase() === report.provider.name.toLowerCase())[0];

                            if (report.provider.name.toLowerCase() === "dbg" || report.provider.name.toLowerCase() === "texinho") {

                                net = bet - win;
                                commission = extra * (percFee != null ? percFee.price : 0) / 100;
                                financial = net - commission;
                            }
                            else {

                                net = bet - win - extra;
                                financial = net * (percFee != null ? percFee.price : 0) / 100;
                                commission = net - financial;
                            }

                            singleSet.bet = bet.toFixed(2);
                            singleSet.win = win.toFixed(2);
                            singleSet.extra = extra.toFixed(2);
                            singleSet.net = net.toFixed(2);
                            singleSet.currency = currency.currency;
                            singleSet.rate = rate.toString();
                            singleSet.euro = parseFloat(net / rate).toFixed(2);
                            singleSet.fee = (percFee != null ? percFee.price : 0);
                            singleSet.commission = parseFloat(commission / rate).toFixed(2);
                            singleSet.financial = parseFloat(financial / rate).toFixed(2);
                            singleSet.rtp = parseFloat(win * 100 / bet).toFixed(2);

                            tBet += parseFloat(bet / rate);
                            tWin += parseFloat(win / rate);
                            tExtra += parseFloat(extra / rate);
                            tComm += parseFloat(commission / rate);

                            if (bet > 0 || win > 0 || extra > 0)
                                dataSet.push(singleSet);
                        }
                    }
                }


                if (dataSet.length > 0) {

                    let table = {};
                    let dataTable = self.state.table;

                    if (this.authAccount.type > 1) {

                        table = {
                            columns: [
                                {
                                    label: "Provider",
                                    field: "provider",
                                    sort: "asc",
                                    width: 150
                                }, {
                                    label: "Bet",
                                    field: "bet",
                                    width: 150
                                }, {
                                    label: "Win",
                                    field: "win",
                                    width: 150
                                }, {
                                    label: "Rake\\Tip",
                                    field: "extra",
                                    width: 150
                                }, {
                                    label: "GGR",
                                    field: "net",
                                    width: 150
                                }, {
                                    label: "Currency",
                                    field: "currency",
                                    width: 150
                                }, {
                                    label: "Rate",
                                    field: "rate",
                                    width: 150
                                }, {
                                    label: "GGR Euro",
                                    field: "euro",
                                    sort: "asc",
                                    width: 150
                                }, {
                                    label: "Commission",
                                    field: "commission",
                                    sort: "asc",
                                    width: 150
                                }, {
                                    label: "Financial",
                                    field: "financial",
                                    width: 150
                                }, {
                                    label: "RTP",
                                    field: "rtp",
                                    width: 150
                                }],
                            rows: []
                        }
                    }
                    else {

                        table = {
                            columns: [
                                {
                                    label: "Vendor",
                                    field: "vendor",
                                    sort: "asc",
                                    width: 150
                                },
                                {
                                    label: "Provider",
                                    field: "provider",
                                    sort: "asc",
                                    width: 150
                                }, {
                                    label: "Bet",
                                    field: "bet",
                                    width: 150
                                }, {
                                    label: "Win",
                                    field: "win",
                                    width: 150
                                }, {
                                    label: "Extra",
                                    field: "extra",
                                    width: 150
                                }, {
                                    label: "GGR",
                                    field: "net",
                                    width: 150
                                }, {
                                    label: "Currency",
                                    field: "currency",
                                    width: 150
                                }, {
                                    label: "Rate",
                                    field: "rate",
                                    width: 150
                                }, {
                                    label: "GGR Euro",
                                    field: "euro",
                                    sort: "asc",
                                    width: 150
                                }, {
                                    label: "Commission",
                                    field: "commission",
                                    sort: "asc",
                                    width: 150
                                }, {
                                    label: "Financial",
                                    field: "financial",
                                    width: 150
                                }, {
                                    label: "RTP",
                                    field: "rtp",
                                    width: 150
                                }],
                            rows: []
                        }
                    }


                    dataSet.forEach((data, index) => {

                        table.rows.push(data);

                        if (table.rows.length === dataSet.length) {

                            const sorted = table.rows.sort((a, b) => {
                                return (a.vendor.toUpperCase() < b.vendor.toUpperCase()) ? -1 : (a.vendor.toUpperCase() > b.vendor.toUpperCase()) ? 1 : 0;
                            });

                            table.rows = sorted.sort((a, b) => {
                                return (a.provider.toUpperCase() < b.provider.toUpperCase()) ? -1 : (a.provider.toUpperCase() > b.provider.toUpperCase()) ? 1 : 0;
                            });

                            let ReportCard =
                                <Row key={affiliate.username + data.provider + index}>
                                    <Col lg="12">
                                        <Card>
                                            <CardHeader>
                                                <h3>{affiliate.username}</h3>
                                                <div className="float-right d-none d-md-block">
                                                    <Button color="primary" className="btn btn-primary waves-effect waves-light" onClick={() => this.ShowDetailsReport(affiliate)} ><i className="mdi mdi-details"></i>{" "}Details</Button>
                                                    {" "}
                                                    <Button color="primary" className="btn btn-primary waves-effect waves-light" onClick={() => this.ShowGroupedDetailsReport(affiliate)} ><i className="mdi mdi-select-group"></i>{" "}Grouped</Button>
                                                    {" "}
                                                    <Button color="primary" className="btn btn-primary waves-effect waves-light" onClick={() => this.DownloadJsonData(table.rows, affiliate.username, true)} ><i className="mdi mdi-download"></i>{" "}Download</Button>
                                                    {" "}
                                                    <Button color="primary" className="btn btn-primary waves-effect waves-light" onClick={() => this.CreateInvoice(affiliate.username, table.rows)} ><i className="mdi mdi-coin-outline"></i>{" "}Invoice</Button>
                                                </div>
                                            </CardHeader>
                                            <CardBody>
                                                <MDBDataTable responsive striped bordered entries={10} order={["date", "asc"]} data={table} />
                                            </CardBody>
                                            <CardFooter>
                                                <Row>
                                                    <Col>
                                                        {"Total BET: " + tBet.toFixed(2) + " EUR"}
                                                    </Col>
                                                    <Col>
                                                        {"Total WIN: " + tWin.toFixed(2) + " EUR"}
                                                    </Col>
                                                    <Col>
                                                        {"Total GGR: " + (tBet - tWin).toFixed(2) + " EUR"}
                                                    </Col>
                                                </Row>
                                            </CardFooter>
                                        </Card>
                                    </Col>
                                </Row>

                            dataTable.push(ReportCard);

                            self.setState({ table: dataTable, loading: false, disabled: false });
                        }
                    });
                }
            });

            // Report Operatori Singoli
            this.state.affiliates.forEach((affiliate) => {

                if (affiliate.username === this.authAccount.username) {

                    for (let z = 0; z < affiliate.operators.length; z++) {

                        let operator = affiliate.operators[z];

                        let tBet = 0;
                        let tWin = 0;
                        let tExtra = 0;
                        let tComm = 0;
                        let dataSet = new Array();

                        for (let i = 0; i < reports.length; i++) {

                            let report = reports[i];

                            for (let j = 0; j < currencies.length; j++) {

                                let currency = currencies[j];

                                let singleSet = {};

                                singleSet.vendor = report.provider.vendor.name;
                                singleSet.provider = report.provider.name;

                                let bet = 0;
                                let win = 0;
                                let extra = 0;
                                let rate = currency.rate;

                                for (let k = 0; k < report.reports.length; k++) {

                                    let data = report.reports[k];

                                    if (data.operator.username === operator.username && data.report.currency === currency.currency) {

                                        bet += data.report.bet;
                                        win += data.report.win;
                                        extra += data.report.extra;
                                    }
                                }

                                let net = 0;
                                let commission = 0;
                                let financial = 0;
                                let percFee = operator.providers.filter(p => p.vendor.name.toLowerCase() === report.provider.vendor.name.toLowerCase() && p.name.toLowerCase() === report.provider.name.toLowerCase())[0];

                                if (report.provider.name.toLowerCase() === "dbg" || report.provider.name.toLowerCase() === "texinho") {

                                    net = bet - win;
                                    commission = extra * (percFee != null ? percFee.price : 0) / 100;
                                    financial = net - commission;
                                }
                                else {

                                    net = bet - win - extra;
                                    financial = net * (percFee != null ? percFee.price : 0) / 100;
                                    commission = net - financial;
                                }

                                singleSet.bet = bet.toFixed(2);
                                singleSet.win = win.toFixed(2);
                                singleSet.extra = extra.toFixed(2);
                                singleSet.net = net.toFixed(2);
                                singleSet.currency = currency.currency;
                                singleSet.rate = rate.toString();
                                singleSet.euro = parseFloat(net / rate).toFixed(2);
                                singleSet.fee = (percFee != null ? percFee.price : 0);
                                singleSet.commission = parseFloat(commission / rate).toFixed(2);
                                singleSet.financial = parseFloat(financial / rate).toFixed(2);
                                singleSet.rtp = parseFloat(win * 100 / bet).toFixed(2);

                                tBet += parseFloat(bet / rate);
                                tWin += parseFloat(win / rate);
                                tExtra += parseFloat(extra / rate);
                                tComm += parseFloat(commission / rate);

                                if (bet > 0 || win > 0 || extra > 0)
                                    dataSet.push(singleSet);
                            }
                        }


                        let table = {};
                        let dataTable = self.state.table;

                        if (this.authAccount.type > 1) {

                            table = {
                                columns: [
                                    {
                                        label: "Provider",
                                        field: "provider",
                                        sort: "asc",
                                        width: 150
                                    }, {
                                        label: "Bet",
                                        field: "bet",
                                        width: 150
                                    }, {
                                        label: "Win",
                                        field: "win",
                                        width: 150
                                    }, {
                                        label: "Rake\\Tip",
                                        field: "extra",
                                        width: 150
                                    }, {
                                        label: "GGR",
                                        field: "net",
                                        width: 150
                                    }, {
                                        label: "Currency",
                                        field: "currency",
                                        width: 150
                                    }, {
                                        label: "Rate",
                                        field: "rate",
                                        width: 150
                                    }, {
                                        label: "GGR Euro",
                                        field: "euro",
                                        sort: "asc",
                                        width: 150
                                    }, {
                                        label: "Commission",
                                        field: "commission",
                                        sort: "asc",
                                        width: 150
                                    }, {
                                        label: "Financial",
                                        field: "financial",
                                        width: 150
                                    }, {
                                        label: "RTP",
                                        field: "rtp",
                                        width: 150
                                    }],
                                rows: []
                            }
                        }
                        else {

                            table = {
                                columns: [
                                    {
                                        label: "Vendor",
                                        field: "vendor",
                                        sort: "asc",
                                        width: 150
                                    },
                                    {
                                        label: "Provider",
                                        field: "provider",
                                        sort: "asc",
                                        width: 150
                                    }, {
                                        label: "Bet",
                                        field: "bet",
                                        width: 150
                                    }, {
                                        label: "Win",
                                        field: "win",
                                        width: 150
                                    }, {
                                        label: "Extra",
                                        field: "extra",
                                        width: 150
                                    }, {
                                        label: "GGR",
                                        field: "net",
                                        width: 150
                                    }, {
                                        label: "Currency",
                                        field: "currency",
                                        width: 150
                                    }, {
                                        label: "Rate",
                                        field: "rate",
                                        width: 150
                                    }, {
                                        label: "GGR Euro",
                                        field: "euro",
                                        sort: "asc",
                                        width: 150
                                    }, {
                                        label: "Commission",
                                        field: "commission",
                                        sort: "asc",
                                        width: 150
                                    }, {
                                        label: "Financial",
                                        field: "financial",
                                        width: 150
                                    }, {
                                        label: "RTP",
                                        field: "rtp",
                                        width: 150
                                    }],
                                rows: []
                            }
                        }


                        dataSet.forEach((data, index) => {

                            table.rows.push(data);

                            if (table.rows.length === dataSet.length) {

                                const sorted = table.rows.sort((a, b) => {
                                    return (a.vendor.toUpperCase() < b.vendor.toUpperCase()) ? -1 : (a.vendor.toUpperCase() > b.vendor.toUpperCase()) ? 1 : 0;
                                });

                                table.rows = sorted.sort((a, b) => {
                                    return (a.provider.toUpperCase() < b.provider.toUpperCase()) ? -1 : (a.provider.toUpperCase() > b.provider.toUpperCase()) ? 1 : 0;
                                });

                                let ReportCard =
                                    <Row key={operator.username + data.provider + index}>
                                        <Col lg="12">
                                            <Card>
                                                <CardHeader>
                                                    <h3>{operator.username}</h3>
                                                    <div className="float-right d-none d-md-block">
                                                        <Button color="primary" className="btn btn-primary waves-effect waves-light" onClick={() => this.ShowDetailsReport(operator)} ><i className="mdi mdi-details"></i>{" "}Details</Button>
                                                        {" "}
                                                        <Button color="primary" className="btn btn-primary waves-effect waves-light" onClick={() => this.DownloadJsonData(table.rows, affiliate.username, true)} ><i className="mdi mdi-download"></i>{" "}Download</Button>
                                                        {" "}
                                                        <Button color="primary" className="btn btn-primary waves-effect waves-light" onClick={() => this.CreateInvoice(affiliate.username, table.rows)} ><i className="mdi mdi-coin-outline"></i>{" "}Invoice</Button>
                                                    </div>
                                                </CardHeader>
                                                <CardBody>
                                                    <MDBDataTable responsive striped bordered entries={10} order={["date", "asc"]} data={table} />
                                                </CardBody>
                                                <CardFooter>
                                                    <Row>
                                                        <Col>
                                                            {"Total BET: " + tBet.toFixed(2) + " EUR"}
                                                        </Col>
                                                        <Col>
                                                            {"Total WIN: " + tWin.toFixed(2) + " EUR"}
                                                        </Col>
                                                        <Col>
                                                            {"Total GGR: " + (tBet - tWin).toFixed(2) + " EUR"}
                                                        </Col>
                                                    </Row>
                                                </CardFooter>
                                            </Card>
                                        </Col>
                                    </Row>

                                dataTable.push(ReportCard);

                                self.setState({ table: dataTable, loading: false, disabled: false });
                            }
                        });
                    }
                }
            });
        }
        else {

            let players = [];

            for (let i = 0; i < reports.length; i++) {

                if (reports[i].reports.length > 0) {

                    let report = reports[i];

                    for (let j = 0; j < report.reports.length; j++) {

                        let player = report.reports[j].operator.name;

                        if (players.indexOf(player) === -1)
                            players.push(player);
                    }
                }
            }


            players.forEach((player) => {

                let tBet = 0;
                let tWin = 0;
                let tExtra = 0;
                let tComm = 0;
                let dataSet = new Array();

                for (let i = 0; i < reports.length; i++) {

                    let report = reports[i];

                    for (let j = 0; j < currencies.length; j++) {

                        let currency = currencies[j];

                        let singleSet = {};

                        singleSet.vendor = report.provider.vendor.name;
                        singleSet.provider = report.provider.name;

                        let bet = 0;
                        let win = 0;
                        let extra = 0;
                        let commission = 0;

                        let rate = currency.rate;

                        for (let k = 0; k < report.reports.length; k++) {

                            let data = report.reports[k];

                            if (data.operator.name === player && data.report.currency === currency.currency) {

                                bet += data.report.bet;
                                win += data.report.win;
                                extra += data.report.extra;
                                commission += data.report.commission;
                            }
                        }

                        let net = bet - win - extra;
                        let financial = net - commission;

                        if (report.provider.name.toLowerCase() === "dbg" || report.provider.name.toLowerCase() === "texinho") {

                            net = bet - win;
                            commission = extra;
                            financial = net - commission;
                        }

                        singleSet.bet = bet.toFixed(2);
                        singleSet.win = win.toFixed(2);
                        singleSet.extra = extra.toFixed(2);
                        singleSet.net = net.toFixed(2);
                        singleSet.currency = currency.currency;
                        singleSet.rate = rate.toString();
                        singleSet.euro = parseFloat(net / rate).toFixed(2);
                        singleSet.commission = parseFloat(commission / rate).toFixed(2);
                        singleSet.financial = parseFloat(financial / rate).toFixed(2);
                        singleSet.rtp = parseFloat(win * 100 / bet).toFixed(2);

                        tBet += parseFloat(bet / rate);
                        tWin += parseFloat(win / rate);
                        tExtra += parseFloat(extra / rate);
                        tComm += parseFloat(commission / rate);

                        if (bet > 0 || win > 0 || extra > 0)
                            dataSet.push(singleSet);
                    }
                }

                if (dataSet.length > 0) {

                    let table = {};
                    let dataTable = self.state.table;

                    if (this.authAccount.type > 1) {

                        table = {
                            columns: [
                                {
                                    label: "Provider",
                                    field: "provider",
                                    sort: "asc",
                                    width: 150
                                }, {
                                    label: "Bet",
                                    field: "bet",
                                    width: 150
                                }, {
                                    label: "Win",
                                    field: "win",
                                    width: 150
                                }, {
                                    label: "Rake\\Tip",
                                    field: "extra",
                                    width: 150
                                }, {
                                    label: "GGR",
                                    field: "net",
                                    width: 150
                                }, {
                                    label: "Currency",
                                    field: "currency",
                                    width: 150
                                }, {
                                    label: "Rate",
                                    field: "rate",
                                    width: 150
                                }, {
                                    label: "GGR Euro",
                                    field: "euro",
                                    sort: "asc",
                                    width: 150
                                }, {
                                    label: "Commission",
                                    field: "commission",
                                    sort: "asc",
                                    width: 150
                                }, {
                                    label: "Financial",
                                    field: "financial",
                                    width: 150
                                }, {
                                    label: "RTP",
                                    field: "rtp",
                                    width: 150
                                }],
                            rows: []
                        }
                    }
                    else {

                        table = {
                            columns: [
                                {
                                    label: "Vendor",
                                    field: "vendor",
                                    sort: "asc",
                                    width: 150
                                },
                                {
                                    label: "Provider",
                                    field: "provider",
                                    sort: "asc",
                                    width: 150
                                }, {
                                    label: "Bet",
                                    field: "bet",
                                    width: 150
                                }, {
                                    label: "Win",
                                    field: "win",
                                    width: 150
                                }, {
                                    label: "Extra",
                                    field: "extra",
                                    width: 150
                                }, {
                                    label: "GGR",
                                    field: "net",
                                    width: 150
                                }, {
                                    label: "Currency",
                                    field: "currency",
                                    width: 150
                                }, {
                                    label: "Rate",
                                    field: "rate",
                                    width: 150
                                }, {
                                    label: "GGR Euro",
                                    field: "euro",
                                    sort: "asc",
                                    width: 150
                                }, {
                                    label: "Commission",
                                    field: "commission",
                                    sort: "asc",
                                    width: 150
                                }, {
                                    label: "Financial",
                                    field: "financial",
                                    width: 150
                                }, {
                                    label: "RTP",
                                    field: "rtp",
                                    width: 150
                                }],
                            rows: []
                        }
                    }

                    dataSet.forEach((data, index) => {

                        table.rows.push(data);

                        if (table.rows.length === dataSet.length) {

                            const sorted = table.rows.sort((a, b) => {
                                return (a.vendor.toUpperCase() < b.vendor.toUpperCase()) ? -1 : (a.vendor.toUpperCase() > b.vendor.toUpperCase()) ? 1 : 0;
                            });

                            table.rows = sorted.sort((a, b) => {
                                return (a.provider.toUpperCase() < b.provider.toUpperCase()) ? -1 : (a.provider.toUpperCase() > b.provider.toUpperCase()) ? 1 : 0;
                            });

                            let ReportCard =
                                <Row key={player + data.provider + index}>
                                    <Col lg="12">
                                        <Card>
                                            <CardHeader>
                                                <h3>{player}</h3>
                                                <div className="float-right d-none d-md-block">
                                                    <Button color="primary" className="btn btn-primary waves-effect waves-light" onClick={() => this.DownloadJsonData(table.rows, player, true)} ><i className="mdi mdi-download"></i>{" "}Download</Button>
                                                </div>
                                            </CardHeader>
                                            <CardBody>
                                                <MDBDataTable responsive striped bordered entries={10} order={["date", "asc"]} data={table} />
                                            </CardBody>
                                            <CardFooter>
                                                <Row>
                                                    <Col>
                                                        {"Total BET: " + tBet.toFixed(2) + " EUR"}
                                                    </Col>
                                                    <Col>
                                                        {"Total WIN: " + tWin.toFixed(2) + " EUR"}
                                                    </Col>
                                                    <Col>
                                                        {"Total GGR: " + (tBet - tWin).toFixed(2) + " EUR"}
                                                    </Col>
                                                </Row>
                                            </CardFooter>
                                        </Card>
                                    </Col>
                                </Row>

                            dataTable.push(ReportCard);

                            self.setState({ table: dataTable, loading: false, disabled: false });
                        }
                    });
                }
            });
        }
    }



    ShowDetailsReport(account) {

        this.setState({ table: [], loading: true, disabled: true });

        let list = [];
        let affiliates = [];

        debugger;
        for (let j = 0; j < this.state.networks.length; j++) {

            let network = this.state.networks[j];

            if (network.parent === account.username) {

                list = [];
                let providers = [];

                for (let k = 0; k < this.state.operators.length; k++) {

                    let operator = this.state.operators[k];

                    if (operator.parent === network.username)
                        list.push(operator);
                }


                for (let k = 0; k < this.state.providers.length; k++) {

                    let provider = network.providers.filter(p => p.vendor.name.toLowerCase() === this.state.providers[k].vendor.name.toLowerCase() && p.name.toLowerCase() === this.state.providers[k].name.toLowerCase())[0];

                    if (provider)
                        providers.push(provider);
                    else
                        providers.push(this.state.providers[k]);
                }


                if (list.length > 0)
                    affiliates.push({ username: network.username, operators: list, providers: providers });
            }
        }


        list = [];
        let providers = [];

        for (let k = 0; k < this.state.operators.length; k++) {

            let operator = this.state.operators[k];

            if (operator.parent === account.username)
                list.push(operator);
        }


        for (let k = 0; k < this.state.providers.length; k++) {

            let provider = account.providers.filter(p => p.vendor.name.toLowerCase() === this.state.providers[k].vendor.name.toLowerCase() && p.name.toLowerCase() === this.state.providers[k].name.toLowerCase())[0];

            if (provider)
                providers.push(provider);
            else
                providers.push(this.state.providers[k]);
        }


        if (list.length > 0)
            affiliates.push({ username: this.authAccount.username, operators: list, providers: providers });


        this.setState({ affiliates: affiliates });

        this.GetReports();
    }



    ShowGroupedDetailsReport(account) {

        this.setState({ table: [], loading: true, disabled: true });

        let list = [];
        let affiliates = [];


        for (let k = 0; k < this.state.operators.length; k++) {

            let operator = this.state.operators[k];

            if (operator.parent === account.username)
                list.push(operator);
        }

        if (list.length > 0)
            affiliates.push({ username: account.username, operators: list, providers: this.state.providers });


        list = [];

        for (let j = 0; j < this.state.networks.length; j++) {

            let network = this.state.networks[j];

            if (network.parent === account.username) {

                list = [];

                for (let k = 0; k < this.state.operators.length; k++) {

                    let operator = this.state.operators[k];

                    if (operator.parent === network.username)
                        list.push(operator);
                }

                if (list.length > 0)
                    affiliates.push({ username: network.username, operators: list, providers: this.state.providers });
            }
        }


        this.setState({ affiliates: affiliates });

        this.GetReports();
    }



    DownloadJsonData(JSONData, FileTitle, ShowLabel) {

        var CSV = '';
        var arrData = typeof JSONData != 'object' ? JSON.parse(JSONData) : JSONData;


        if (ShowLabel) {

            var row = "";

            for (var index in arrData[0])
                row += index.toUpperCase() + ',';

            row = row.slice(0, -1);
            CSV += row + '\r\n';
        }


        for (var i = 0; i < arrData.length; i++) {

            var row = "";

            for (var index in arrData[i])
                row += '"' + arrData[i][index] + '",';

            row.slice(0, row.length - 1);
            CSV += row + '\r\n';
        }


        if (CSV == '') {

            alert("Invalid data");
            return;
        }


        var filename = FileTitle + " " + (new Date());
        var blob = new Blob([CSV], {
            type: 'text/csv;charset=utf-8;'
        });

        if (navigator.msSaveBlob) { // IE 10+
            navigator.msSaveBlob(blob, filename);
        } else {
            var link = document.createElement("a");
            if (link.download !== undefined) { // feature detection
                // Browsers that support HTML5 download attribute
                var url = URL.createObjectURL(blob);
                link.setAttribute("href", url);
                link.style = "visibility:hidden";
                link.download = filename + ".csv";
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }
    }



    CreateInvoice(account, data) {

        const sorted = data.sort((a, b) => {
            return (a.provider.toUpperCase() < b.provider.toUpperCase()) ? -1 : (a.provider.toUpperCase() > b.provider.toUpperCase()) ? 1 : 0;
        });

        if (sorted.length > 0) {

            let total = 0;
            let first = { provider: sorted[0].provider, bet: sorted[0].bet / sorted[0].rate, win: sorted[0].win / sorted[0].rate, extra: sorted[0].extra / sorted[0].rate, fee: sorted[0].fee, financial: sorted[0].financial / 1 };

            let newList = new Array(first);

            for (let i = 1; i < sorted.length; i++) {

                if (sorted[i].provider == newList[newList.length - 1].provider) {

                    newList[newList.length - 1].bet += sorted[i].bet / sorted[i].rate;
                    newList[newList.length - 1].win += sorted[i].win / sorted[i].rate;
                    newList[newList.length - 1].extra += sorted[i].extra / sorted[i].rate;
                    newList[newList.length - 1].financial += sorted[i].financial / 1;
                }
                else {

                    newList.push({ provider: sorted[i].provider, bet: sorted[i].bet / sorted[i].rate, win: sorted[i].win / sorted[i].rate, extra: sorted[i].extra / sorted[i].rate, fee: sorted[i].fee, financial: sorted[i].financial / 1 });
                }
            }

            var monthName = new Intl.DateTimeFormat("en-US", { month: "long" }).format;

            let TXT = "Invoice " + account + " " + monthName(new Date(this.state.start)) + " " + new Date(this.state.start).getFullYear();
            TXT += "\r\n";
            TXT += "\r\n";

            for (let i = 0; i < newList.length; i++) {

                if (newList[i].provider.toLowerCase() == "texinho") {

                    TXT += (i + 1) + " - " + newList[i].provider + " --> Bet: " + newList[i].bet.toFixed(2) + " - Win: " + newList[i].win.toFixed(2) + " - Rake: " + newList[i].extra.toFixed(2) + " --> Financial: " + newList[i].financial.toFixed(2) + " €\r\n";
                    total += newList[i].financial;
                }
                else {

                    TXT += (i + 1) + " - " + newList[i].provider + " --> Bet: " + newList[i].bet.toFixed(2) + " - Win: " + newList[i].win.toFixed(2) + " - GGR: " + (newList[i].bet - newList[i].win).toFixed(2) + " --> Financial: " + newList[i].financial.toFixed(2) + " €\r\n";
                    total += (newList[i].financial > 0 ? newList[i].financial : 0);
                }
            }

            TXT += "\r\n";
            TXT += "Invoice Total: " + total.toFixed(2) + " €";


            /*var filename = "Invoice_" + account + "_" + monthName(new Date(this.state.start));
            var blob = new Blob([TXT], {
                type: 'text/plain;charset=utf-8;'
            });

            if (navigator.msSaveBlob) { // IE 10+
                navigator.msSaveBlob(blob, filename);
            } else {
                var link = document.createElement("a");
                if (link.download !== undefined) { // feature detection
                    // Browsers that support HTML5 download attribute
                    var url = URL.createObjectURL(blob);
                    link.setAttribute("href", url);
                    link.style = "visibility:hidden";
                    link.download = filename + ".txt";
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                }
            }*/

            this.setState({ customer: account, date: monthName(new Date(this.state.start)) + " " + new Date(this.state.start).getFullYear(), invoice: newList, invoiceModal: true });
        }
    }


    toggle() {

        this.setState(prevState => ({
            invoiceModal: !prevState.invoiceModal, disabled: false, alert: null
        }));
    }


    GetResellerNetworks(index) {

        let list = [];
        let self = this;

        if (index >= 0) {

            let reseller = this.state.resellers[index];

            Backend.getAccounts("network", (data) => {

                data.forEach((network, index) => {

                    if (network.parent === reseller.username)
                        list.push(network);

                    if (index === data.length - 1)
                        self.setState({ networks: list });
                });
            });
        }
        else
            Backend.getAccounts("network", (data) => { this.setState({ networks: data }) });


        this.GetResellerOperators(index);
    }



    GetResellerOperators(index) {

        let list = [];
        let self = this;

        if (index >= 0) {

            let reseller = this.state.resellers[index];

            Backend.getAccounts("operator", (data) => {

                data.forEach((operator, index) => {

                    if (operator.parent === reseller.username)
                        list.push(operator);

                    if (index === data.length - 1) {

                        let affiliates = [];
                        affiliates.push({ username: this.authAccount.username, operators: list });
                        self.setState({ operators: list, affiliates: affiliates });
                    }
                });
            });
        }
        else
            Backend.getAccounts("operator", (data) => { this.setState({ operators: data, reseller: null }); this.GetAffiliates(); });
    }



    GetNetworkOperators(index) {

        let list = [];
        let self = this;

        if (index >= 0) {

            let network = this.state.networks[index];

            Backend.getAccounts("operator", (data) => {

                data.forEach((operator, index) => {

                    if (operator.parent === network.username)
                        list.push(operator);

                    if (index === data.length - 1) {

                        let affiliates = [];
                        affiliates.push({ username: this.authAccount.username, operators: list });
                        self.setState({ operators: list, affiliates: affiliates });
                    }
                });
            });
        }
        else
            Backend.getAccounts("operator", (data) => { this.setState({ operators: data, network: null }); this.GetAffiliates(); });
    }



    GetReports() {

        this.reports = [];
        this.setState({ table: [], loading: true, disabled: true });

        let self = this;
        const providers = this.state.providers;

        providers.forEach((provider) => {

            if (provider.name === this.state.provider || self.state.provider == null)
                self.GetProviderReports(provider);
            else
                this.reports.push({ provider: provider, reports: [] });
        });
    }



    async GetProviderReports(provider) {

        let op = null;

        try {

            if (this.authAccount.type > 2)
                op = this.authAccount;
            else
                op = this.state.operator;


            Backend.getReports(provider.vendor.name, provider.name, op, this.state.start, this.state.end,
                (data) => {

                    let dataSet = new Array();
                    let singleSet = new Array();

                    if (data != null && data.length > 0) {

                        if (op == null) {

                            for (let i = 0; i < this.state.operators.length; i++) {

                                let operator = this.state.operators[i];

                                if (op === null || (op != null && op.username === operator.username)) {

                                    for (let j = 0; j < data.length; j++) {

                                        let report = data[j];

                                        if (operator.username === report.client.name) {

                                            if (report.bet > 0 || report.win > 0 || report.extra > 0) {

                                                singleSet = {};

                                                singleSet.bet = report.bet;
                                                singleSet.win = report.win;
                                                singleSet.extra = report.extra;

                                                let net = report.bet - report.win - report.extra;
                                                singleSet.net = net;

                                                if (report.currency != null)
                                                    singleSet.currency = report.currency.toUpperCase();
                                                else
                                                    singleSet.currency = "ERROR";

                                                let commission = 0;

                                                /*if (!extra) commission = net - (net * getCommission(account.owner, prod.name) / 100);
                                                else commission = report.extra - (report.extra * getCommission(account.owner, prod.name) / 100);*/

                                                singleSet.commission = commission;
                                                singleSet.financial = (net - commission);

                                                dataSet.push({ operator: operator, report: singleSet });
                                            }
                                        }
                                    }
                                }
                            }

                            this.reports.push({ provider: provider, reports: dataSet });
                        }
                        else {

                            for (let j = 0; j < data.length; j++) {

                                let report = data[j];

                                if (report.bet > 0 || report.win > 0 || report.extra > 0) {

                                    singleSet = {};

                                    singleSet.user = report.user;

                                    singleSet.bet = report.bet;
                                    singleSet.win = report.win;
                                    singleSet.extra = report.extra;

                                    let net = report.bet - report.win - report.extra;
                                    singleSet.net = net;

                                    if (report.currency != null)
                                        singleSet.currency = report.currency.toUpperCase();
                                    else
                                        singleSet.currency = "ERROR";

                                    let commission = 0;

                                    /*if (!extra) commission = net - (net * getCommission(account.owner, prod.name) / 100);
                                    else commission = report.extra - (report.extra * getCommission(account.owner, prod.name) / 100);*/

                                    singleSet.commission = commission;
                                    singleSet.financial = (net - commission);

                                    dataSet.push({ operator: report.user, report: singleSet });
                                }
                            }

                            this.reports.push({ provider: provider, reports: dataSet });
                        }
                    }
                    else
                        this.reports.push({ provider: provider, reports: [] });


                    if (this.reports.length === this.state.providers.length) {

                        this.setState({ operator: op });
                        this.RenderTable(this.reports);
                    }
                },
                () => {

                    this.reports.push({ provider: provider, reports: [] });
                }
            );
        }
        catch (ex) {

            this.reports.push({ provider: provider, reports: [] });
        }
    }



    render() {

        let { resellers, networks, operators, providersDropDown } = this.state;

        return (
            this.state.invoiceModal ?
                <InvoiceModal
                    customer={this.state.customer}
                    date={new Date().toLocaleDateString()}
                    data={this.state.invoice}
                    interval={new Date(this.state.start).toLocaleDateString() + " - " + new Date(this.state.end).toLocaleDateString()}
                    close={() => this.toggle()}
                /> :
                <React.Fragment>
                    <div className="container-fluid">
                        <Row className="align-items-center">
                            <Col sm="6">
                                <div className="page-title-box">
                                    <h4 className="font-size-18">Reports List</h4>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg="12">
                                <Card>
                                    <CardHeader>
                                        <FormGroup row>
                                            <Col>
                                                <Label htmlFor="select">Providers:</Label>
                                                <select defaultValue={providersDropDown.length === 1 ? "0" : ""} className="form-control" onChange={(e) => this.setState({ provider: this.state.providersDropDown[parseInt(e.target.value)] })} >
                                                    {providersDropDown.length === 1 ?
                                                        <option key={0} value={"0"}>{providersDropDown[0]}</option> :
                                                        <option value={""}>All Providers</option>}
                                                    {providersDropDown.length !== 1 ?
                                                        providersDropDown.map((provider, index) => (
                                                            <option key={index} value={index}>{provider}</option>
                                                        )) : null}
                                                </select>
                                            </Col>
                                            {this.authAccount.type === 0 ?
                                                <Col>
                                                    <Label htmlFor="select">Resellers:</Label>
                                                    <select defaultValue={0} className="form-control" onChange={(e) => parseInt(e.target.value) >= 0 ? this.GetResellerNetworks(parseInt(e.target.value)) : this.setState({ reseller: null })}  >
                                                        <option value={-1}>All Resellers</option>
                                                        {resellers.map((reseller, index) => (
                                                            <option key={index} value={index}>{reseller.username}</option>
                                                        ))}
                                                    </select>
                                                </Col> : null}
                                            {this.authAccount.type < 2 ?
                                                <Col>
                                                    <Label htmlFor="select">Networks:</Label>
                                                    <select defaultValue={0} className="form-control" onChange={(e) => parseInt(e.target.value) >= 0 ? this.GetNetworkOperators(parseInt(e.target.value)) : this.setState({ network: null })}  >
                                                        <option value={-1}>All Networks</option>
                                                        {networks.map((network, index) => (
                                                            <option key={index} value={index}>{network.username}</option>
                                                        ))}
                                                    </select>
                                                </Col> : null}
                                            {this.authAccount.type < 3 ?
                                                <Col>
                                                    <Label htmlFor="select">Operators:</Label>
                                                    <select defaultValue={-1} className="form-control" onChange={(e) => parseInt(e.target.value) >= 0 ? this.setState({ operator: this.state.operators[parseInt(e.target.value)] }) : this.setState({ operator: null })} >
                                                        <option value={-1}>All Operators</option>
                                                        {operators.map((operator, index) => (
                                                            <option key={index} value={index}>{operator.username}</option>
                                                        ))}
                                                    </select>
                                                </Col> : null}
                                        </FormGroup>
                                        <FormGroup row>
                                            <Col sm="4">
                                                <Label htmlFor="datetime-from">From:</Label>
                                                <input className="form-control" type="datetime-local" value={this.state.start} onChange={(e) => this.setState({ start: e.target.value })} />
                                            </Col>
                                            <Col sm="4">
                                                <Label htmlFor="datetime-to">To:</Label>
                                                <input className="form-control" type="datetime-local" value={this.state.end} onChange={(e) => this.setState({ end: e.target.value })} />
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Col sm="2">
                                                <Button color="primary" className="btn btn-primary waves-effect waves-light" disabled={this.state.disabled} onClick={() => this.GetReports()} ><i className="mdi mdi-card-search-outline"></i>{" "}Search</Button>
                                            </Col>
                                        </FormGroup>
                                    </CardHeader>
                                    <CardBody>
                                        {this.state.table !== null && this.state.table.length > 0 ? this.state.table.map((card) => (card)) : (this.state.loading ? <span>Loading Data...</span> : <span>No Data Available</span>)}
                                    </CardBody>
                                </Card>
                            </Col >
                        </Row>
                    </div>
                </React.Fragment >
        );
    }
}

export default AccountReports; 