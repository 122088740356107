import React, { Component } from "react";
import { Switch, BrowserRouter as Router } from "react-router-dom";
import { connect } from "react-redux";

// Import Routes
import { authProtectedRoutes, publicRoutes } from "./routes/";
import AppRoute from "./routes/route";

// layouts
import VerticalLayout from "./components/VerticalLayout/";
import HorizontalLayout from "./components/HorizontalLayout/";
import NonAuthLayout from "./components/NonAuthLayout";

// Import scss
import "./assets/scss/theme.scss";

// Import Backend
import { initBackend } from "./helpers/Backend";


const Config = {
    //apiUrl: "https://localhost:44345",
    apiUrl: "https://hub.riseupgames.net",
    apiKey: "AIzaSyBUctS2mlPY58Y-E1AypSUz7OO0zhlBLC8"
};


// Init Backend
initBackend(Config);




class App extends Component {

    constructor(props) {

        super(props);

        this.state = {};

        this.getLayout = this.getLayout.bind(this);
    }



    componentDidMount() {


    }



    getLayout = () => {

        let layoutCls = VerticalLayout;

        switch (this.props.layout.layoutType) {

            case "horizontal":
                layoutCls = HorizontalLayout;
                break;


            default:
                layoutCls = VerticalLayout;
                break;
        }

        return layoutCls;
    };



    render() {

        const Layout = this.getLayout();

        return (
            <React.Fragment>
                <Router>
                    <Switch>
                        {publicRoutes.map((route, idx) => (
                            <AppRoute
                                path={route.path}
                                layout={NonAuthLayout}
                                component={route.component}
                                key={idx}
                                isAuthProtected={false}
                            />
                        ))}

                        {authProtectedRoutes.map((route, idx) => (
                            <AppRoute
                                path={route.path}
                                layout={Layout}
                                component={route.component}
                                key={idx}
                                isAuthProtected={true}
                            />
                        ))}
                    </Switch>
                </Router>
            </React.Fragment>
        );
    }
}



const mapStateToProps = state => {
    return {
        layout: state.Layout
    };
};



export default connect(mapStateToProps, null)(App);