import React, { Component } from "react";
import { Row, Col, Button, Card, CardHeader, CardBody } from "reactstrap";
import { MDBDataTable } from "mdbreact";
import SweetAlert from "react-bootstrap-sweetalert";

import AccountModal from "../../components/AccountModal";

import { getBackend } from "../../helpers/Backend";

import publicIp from "public-ip";


let Backend;



class Account extends Component {

    constructor(props) {

        super(props);


        this.state = {
            accountModal: false,
            account: {},
            accounts: [],
            owners: [],
            providers: [],
            configurations: [],
            type: this.props.location.pathname.split('/')[2] !== null ? this.props.location.pathname.split('/')[2].toLowerCase() : "",
            disabled: false,
            loading: false,
            alert: null,
            table: null
        };


        Backend = getBackend();

        this.authAccount = Backend.getAuthenticatedUser();

        this.toggle = this.toggle.bind(this);
        this.SetValue = this.SetValue.bind(this);
        this.AddAccount = this.AddAccount.bind(this);
        this.RenderTable = this.RenderTable.bind(this);
    }



    componentDidMount() {

        let type = "support";

        let account = this.state.account;
        this.authAccount = Backend.getAuthenticatedUser();

        account.owner = this.authAccount.id;
        account.type = 0;


        this.getClientIp().then(
            (data) => {

                account.regIP = data;
                account.lastIP = data;

                this.setState({ type: type, account: account });
            },
            () => {

                account.regIP = "";
                account.lastIP = "";

                this.setState({ type: type, account: account });
            }
        );


        this.setState({ loading: true });

        if (this.authAccount.type === 0 && this.authAccount.id === 1)
            Backend.getProviders((data) => { this.setState({ providers: data }) });
        else
            this.setState({ providers: this.authAccount.providers });

        Backend.getAccounts(type, (data) => { this.setState({ accounts: data, loading: false }); this.RenderTable(data) });
    }



    RenderTable(data) {

        let self = this;
        let table = {
            columns: [
                {
                    label: "ID",
                    field: "id",
                    sort: "asc",
                    width: 150
                }, {
                    label: "Username",
                    field: "username",
                    sort: "asc",
                    width: 150
                }, {
                    label: "Owner",
                    field: "parent",
                    sort: "asc",
                    width: 150
                }, {
                    label: "Last IP",
                    field: "lastIP",
                    width: 150
                }, {
                    label: "Last Activity (local time)",
                    field: "lastDate",
                    width: 150
                }, {
                    label: "Action",
                    field: "action",
                    width: 150
                }],
            rows: []
        }

        const dataTable = JSON.parse(JSON.stringify(data));

        dataTable.forEach(function (item, index) {

            let date = new Date(item.lastDate);

            item.lastDate = date.toLocaleDateString() + " " + date.toLocaleTimeString();
            item.action = self.action(index);

            table.rows.push(item);

            if (index === data.length - 1)
                self.setState({ table: <MDBDataTable responsive striped bordered data={table} /> });
        });
    }



    action(index) {

        return (
            <Col>
                <Button block color="success" onClick={() => { this.OpenEditModal(index) }}>Edit</Button>
                <Button block color="warning" onClick={() => { this.OpenCopyModal(index) }}>Copy</Button>
                <Button block color="danger">Disable</Button>
            </Col>
        );
    }



    toggle() {

        this.setState(prevState => ({
            accountModal: !prevState.accountModal, disabled: false, alert: null
        }));
    }



    getClientIp = async () => await publicIp.v4({
        fallbackUrls: ["https://ifconfig.co/ip"]
    });



    isDisabled() {

        let { account, disabled } = this.state;
        let update = account != null && account.id > 0;

        if (!disabled) {

            if (account.username != null && account.username.length >= 3 && ((account.password != null && account.password.length >= 6) || update))
                return false;
            else
                return true;
        }
        else
            return true;
    }



    SetValue(value, target) {

        switch (target.type) {

            case "text":
            case "email":
            case "select":
            case "password":
            case "select-one":
                if (isNaN(target.value)) {
                    if (target.value.indexOf(',') === -1)
                        value[target.name] = target.value;
                    else
                        value[target.name] = target.value.split(',');
                }
                else
                    value[target.name] = parseInt(target.value);
                break;


            case "number":
                if (!isNaN(target.value))
                    value[target.name] = parseFloat(target.value);
                break;


            case "checkbox":
                value[target.name] = target.checked;
                break;


            default:
                break;
        }

        return value;
    }



    AddAccount() {

        let account = this.state.account;

        this.setState({ disabled: true });

        if (account.ip != null && !Array.isArray(account.ip))
            account.ip = this.state.account.ip.split(',');

        account.support = true;

        Backend.addSupport(account, (data) => this.setState({ alert: { status: data } }));
    }



    UpdateAccount() {

        let account = this.state.account;

        this.setState({ disabled: true });

        if (account.ip != null && !Array.isArray(account.ip))
            account.ip = this.state.account.ip.split(',');

        Backend.updateAccount(this.state.account.username, account, (data) => { this.setState({ alert: { status: data, disabled: false } }); });
    }



    OpenAddModal() {

        this.setState({ account: {}, accountModal: true });
    }



    OpenEditModal(index) {

        let account = this.state.accounts[index];

        this.setState({ account: account, accountModal: true });
    }



    OpenCopyModal(index) {

        let account = this.state.accounts[index];
        account.id = 0;

        this.setState({ account: account, accountModal: true });
    }



    HandleProvider(provider) {

        let account = this.state.account;

        if (account.providers == null)
            account.providers = [];

        let index = account.providers.findIndex(pp => { if (pp.name === provider.name && pp.vendor.name === provider.vendor.name) return true; });

        if (index < 0)
            account.providers.push(provider);
        else
            account.providers.splice(index, 1);

        this.setState({ account: account });
    }



    HandleConfiguration(value) {

        let id = parseInt(value);
        let account = this.state.account;
        let configurations = this.state.configurations;

        if (account.providers == null)
            account.providers = [];

        configurations.forEach((configuration) => {

            if (configuration.id === id) {

                account.providers.forEach((provider, index) => {

                    if (provider.name === configuration.provider.name && provider.vendor.name === configuration.provider.vendor.name) {

                        account.providers[index].configuration = configuration;

                        this.setState({ account: account });
                    }
                });
            }
        });
    }



    render() {

        let { account, owners, providers, configurations } = this.state;
        let update = account != null && account.id > 0;

        return (
            <React.Fragment>

                {this.state.accountModal ?
                    <AccountModal
                        authAccount={this.authAccount}
                        title={(update ? "Update " : "Add ") + this.state.type.replace(/^\w/, c => c.toUpperCase())}
                        account={account}
                        owners={owners}
                        providers={providers}
                        configurations={configurations}
                        handleChange={(e) => this.setState({ account: this.SetValue(account, e.target) })}
                        handleProvider={(provider) => this.HandleProvider(provider)}
                        handleConfiguration={(configuration) => this.HandleConfiguration(configuration)}
                        close={() => this.toggle()}
                        save={() => { update ? this.UpdateAccount() : this.AddAccount() }}
                        disabled={this.isDisabled()}
                        type={0}
                    /> : null}

                {this.state.alert ? (
                    this.state.alert.status ?
                        <SweetAlert
                            style={{ color: "black" }}
                            title="Good job!"
                            success
                            confirmBtnBsStyle="success"
                            onConfirm={() => { this.toggle(); Backend.getAccounts(this.props.location.pathname.split('/')[2].toLowerCase(), (data) => { this.setState({ accounts: data, loading: false }); this.RenderTable(data) }) }}
                        >
                            New Account Created
                        </SweetAlert> :
                        <SweetAlert
                            style={{color: "black"}}
                            title="Error!"
                            warning
                            confirmBtnBsStyle="success"
                            onConfirm={() => this.setState({ alert: null, disabled: false })}
                        >
                            Something went wrong!
                        </SweetAlert>) : null
                }

                <div className="container-fluid">
                    <Row className="align-items-center">
                        <Col sm="6">
                            <div className="page-title-box">
                                <h4 className="font-size-18">{this.state.type.replace(/^\w/, c => c.toUpperCase()) + "s " + "List"}</h4>
                            </div>
                        </Col>

                        <Col sm="6">
                            <div className="float-right d-none d-md-block">
                                <Button color="primary" className="btn btn-primary waves-effect waves-light" onClick={() => this.OpenAddModal()} ><i className="mdi mdi-account-multiple-plus-outline"></i>{" "}Add</Button>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg="12">
                            <Card>
                                <CardHeader>
                                </CardHeader>
                                <CardBody>
                                    {this.state.table || (this.state.loading ? <span>Loading Data...</span> : <span>No Data Available</span>)}
                                </CardBody>
                            </Card>
                        </Col >
                    </Row>
                </div>
            </React.Fragment>);
    }
}

export default Account;