import React, { Component } from "react";
import { Row, Col, Card, CardBody, Modal, InputGroup, Input, InputGroupAddon, Button } from "reactstrap";
import Switch from "react-switch";


import InputForm from "./InputForm";



const Offsymbol = props => {
    return (
        <div
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                fontSize: 12,
                color: "#fff",
                paddingRight: 2
            }}
        >
            {" "}
          No
        </div>
    );
};


const OnSymbol = props => {
    return (
        <div
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                fontSize: 12,
                color: "#fff",
                paddingRight: 2
            }}
        >
            {" "}
          Yes
        </div>
    );
};




class AccountModal extends Component {

    isProviderEnabled(account, provider) {

        if (account != null && account.providers != null && account.providers.filter((e) => e.name === provider.name && e.vendor.name === provider.vendor.name).length !== 0) {

            let index = account.providers.findIndex(pp => { if (pp.name === provider.name && pp.vendor.name === provider.vendor.name) return true; });

            if (index >= 0 && account.providers[index].enabled)
                return true;
        }

        return false;
    }



    GetConfigurationID(provider, providers) {

        if (providers != null)
            for (let i = 0; i < providers.length; i++)
                if (providers[i].name === provider.name && providers[i].vendor.name == provider.vendor.name)
                    return providers[i].configuration !== null ? providers[i].configuration.id : 0;

        return 0;
    }



    GetAvailableConfigurations(provider) {

        let list = [];
        let configurations = this.props.configurations;

        for (let i = 0; i < configurations.length; i++) {

            let configuration = configurations[i];

            if (configuration.provider.name == provider.name && configuration.provider.vendor.name == provider.vendor.name)
                list.push(configuration);
        }

        return list;
    }



    GenerateApiKey(length) {

        let apiKey = "";
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const charactersLength = characters.length;

        for (let i = 0; i < length; i++)
            apiKey += characters.charAt(Math.floor(Math.random() * charactersLength));

        let e = {};
        e.target = {};
        e.target.type = "text";
        e.target.name = "apiKey";
        e.target.value = apiKey;

        this.props.handleChange(e);
    }



    GetProviderInfo(account, p) {

        let provider = JSON.parse(JSON.stringify(p));
        let providers = account.providers;

        provider.price = 0;
        provider.limit = 0;

        if (account.type !== 0) {

            if (providers != null) {

                for (let i = 0; i < providers.length; i++) {

                    if (providers[i].vendor.name === provider.vendor.name && providers[i].name === provider.name) {

                        provider.price = providers[i].price ?? 0;
                        provider.limit = providers[i].limit ?? 0;

                        break;
                    }
                }
            }
        }
        else {

            provider.price = 100;
            provider.limit = 0;
        }

        return provider;
    }



    render() {

        let { authAccount, title, account, type, owners, providers, close, save, disabled, handleChange, handleProvider, handleConfiguration } = this.props;

        return (
            <Modal isOpen={true} toggle={close} className="modal-lg">
                <div className="modal-header">
                    <h5 className="modal-title mt-0" id="myModalLabel">
                        {title}
                    </h5>
                    <button type="button" onClick={close} className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    <div className="col-12">
                        <Card>
                            <CardBody>
                                <InputForm name="username" type="text" label="Username" placeholder="Username" value={account} text="Min. 3 - Max. 50 chars" handleChange={(e) => handleChange(e)} />
                                <InputForm name="password" htmlFor="" type="password" label="Password" placeholder="Password" value={account} text="Min. 6 - Max. 50 chars" handleChange={(e) => handleChange(e)} />
                                {type === 3 ?
                                    <Row className="form-group">
                                        <label htmlFor="apiKey" className="col-sm-2 col-form-label">ApiKey</label>
                                        <Col sm={10}>
                                            <InputGroup>
                                                <input className="form-control" type="text" name="apiKey" placeholder="ApiKey" value={account.apiKey || ""} onChange={e => handleChange(e)} />
                                                <InputGroupAddon addonType="append">
                                                    <Button type="button" color="primary" onClick={() => this.GenerateApiKey(16)}>Generate</Button>
                                                </InputGroupAddon>
                                            </InputGroup>
                                        </Col>
                                    </Row> : null
                                }
                                {type > 0 ?
                                    <>
                                        <InputForm name="email" type="email" label="Email" placeholder="Email" value={account} handleChange={(e) => handleChange(e)} />
                                        <Row className="form-group">
                                            <label className="col-sm-2 col-form-label">Owner</label>
                                            <Col sm={10}>
                                                <select name="owner" defaultValue={account.owner != null ? account.owner : 0} className="form-control" onChange={(e) => handleChange(e)} >
                                                    <option value={0} disabled>Not Set</option>
                                                    {owners.map((owner, index) => (
                                                        <option key={index} value={owner.id}>{owner.username}</option>
                                                    ))}
                                                </select>
                                            </Col>
                                        </Row>
                                    </> : null
                                }
                                {type === 3 ?
                                    <>
                                        <InputForm name="ip" htmlFor="" type="text" label="Whitelist IP" placeholder="127.0.0.1,127.0.0.2" value={account} text="use the ',' to enter other IPs" handleChange={(e) => handleChange(e)} />

                                        <Row className="form-group"><label className="col-sm-12 col-form-label">Seamless:</label></Row>
                                        <InputForm name="balanceURL" htmlFor="" type="text" label="Balance" placeholder="https://wwww.domain.com/getbalance" value={account} handleChange={(e) => handleChange(e)} />
                                        <InputForm name="transactionURL" htmlFor="" type="text" label="Transaction" placeholder="https://wwww.domain.com/addtransaction" value={account} handleChange={(e) => handleChange(e)} />
                                    </> : null
                                }
                                <br />
                                {type !== 3 ?
                                    <>
                                        <Row className="form-group">
                                            {authAccount.type === 0 ?
                                                !account.support ?
                                                    <>
                                                        <label className="col-sm-2 col-form-label">Vendor</label>
                                                        <label className="col-sm-2 col-form-label">Provider</label>
                                                        <label className="col-sm-2 col-form-label"></label>
                                                        <label className="col-sm-3 col-form-label">Commission</label>
                                                        <label className="col-sm-3 col-form-label">Limit</label>
                                                    </> :
                                                    <>
                                                        <label className="col-sm-5 col-form-label">Vendor</label>
                                                        <label className="col-sm-5 col-form-label">Provider</label>
                                                        <label className="col-sm-2 col-form-label"></label>
                                                    </> :
                                                <>
                                                    <label className="col-sm-4 col-form-label">Provider</label>
                                                    <label className="col-sm-2 col-form-label"></label>
                                                    <label className="col-sm-6 col-form-label">Commission</label>
                                                </>
                                            }
                                        </Row>
                                        {providers.map((provider, index) => (
                                            <Row key={index} className="form-group">
                                                {authAccount.type === 0 ?
                                                    !account.support ?
                                                        <>
                                                            <Col sm="2">
                                                                <label htmlFor={"Vendor-" + provider.vendor.name}>{provider.vendor.name}</label>
                                                            </Col>
                                                            <Col sm="2">
                                                                <label htmlFor={"Provider-" + provider.name}>{provider.name}</label>
                                                            </Col>
                                                            <Col sm="2">
                                                                <Switch
                                                                    uncheckedIcon={<Offsymbol />}
                                                                    checkedIcon={<OnSymbol />}
                                                                    onColor="#626ed4"
                                                                    onChange={() => { handleProvider(provider) }}
                                                                    checked={this.isProviderEnabled(account, provider)}
                                                                />
                                                            </Col>
                                                            <Col sm="3">
                                                                <Input type="number" name="price" placeholder="0.00 %" value={this.GetProviderInfo(account, provider).price || "0"} onChange={(e) => { handleProvider(provider, e) }} />
                                                                <span className="text-muted" style={{ fontSize: "10px" }} >{"The percentage of GGR to which the product is supplied - Max.(" + this.GetProviderInfo(authAccount, provider).price + ")"}</span>
                                                            </Col>
                                                            <Col sm="3">
                                                                <Input type="number" name="limit" placeholder="0" value={this.GetProviderInfo(account, provider).limit || "0"} onChange={(e) => { handleProvider(provider, e) }} />
                                                                <span className="text-muted" style={{ fontSize: "10px" }} >{"Consumption limit (0 = no limit)"}</span>
                                                            </Col>
                                                        </> :
                                                        <>
                                                            <Col sm="5">
                                                                <label htmlFor={"Vendor-" + provider.vendor.name}>{provider.vendor.name}</label>
                                                            </Col>
                                                            <Col sm="5">
                                                                <label htmlFor={"Provider-" + provider.name}>{provider.name}</label>
                                                            </Col>
                                                            <Col sm="2">
                                                                <Switch
                                                                    uncheckedIcon={<Offsymbol />}
                                                                    checkedIcon={<OnSymbol />}
                                                                    onColor="#626ed4"
                                                                    onChange={() => { handleProvider(provider) }}
                                                                    checked={this.isProviderEnabled(account, provider)}
                                                                />
                                                            </Col>
                                                        </> :
                                                    <>
                                                        <Col sm="4">
                                                            <label htmlFor={"Provider-" + provider.name}>{provider.name}</label>
                                                        </Col>
                                                        <Col sm="2">
                                                            <Switch
                                                                uncheckedIcon={<Offsymbol />}
                                                                checkedIcon={<OnSymbol />}
                                                                onColor="#626ed4"
                                                                onChange={() => { handleProvider(provider) }}
                                                                checked={this.isProviderEnabled(account, provider)}
                                                            />
                                                        </Col>
                                                        <Col sm="6">
                                                            <Input type="number" name="price" placeholder="0.00 %" value={this.GetProviderInfo(account, provider).price || "0"} onChange={(e) => { handleProvider(provider, e) }} />
                                                            <span className="text-muted" style={{ fontSize: "10px" }} >{"The percentage of GGR to which the product is supplied - Max.(" + this.GetProviderInfo(authAccount, provider).price + ")"}</span>
                                                        </Col>
                                                    </>}
                                            </Row>))}
                                    </> :
                                    <>
                                        <Row className="form-group">
                                            {authAccount.type === 0 ?
                                                <>
                                                    <label className="col-sm-2 col-form-label">Vendor</label>
                                                    <label className="col-sm-2 col-form-label">Provider</label>
                                                    <label className="col-sm-2 col-form-label"></label>
                                                    <label className="col-sm-4 col-form-label">Configuration</label>
                                                    <label className="col-sm-2 col-form-label">Commission</label>
                                                </> :
                                                <>
                                                    <label className="col-sm-3 col-form-label">Provider</label>
                                                    <label className="col-sm-2 col-form-label"></label>
                                                    <label className="col-sm-4 col-form-label">Configuration</label>
                                                    <label className="col-sm-3 col-form-label">Commission</label>
                                                </>
                                            }
                                        </Row>
                                        {providers.map((provider, index) => (
                                            <Row key={index} className="form-group">
                                                {authAccount.type === 0 ?
                                                    <>
                                                        <Col sm="2">
                                                            <label htmlFor={"Vendor-" + provider.vendor.name}>{provider.vendor.name}</label>
                                                        </Col>
                                                        <Col sm="2">
                                                            <label htmlFor={"Provider-" + provider.name}>{provider.name}</label>
                                                        </Col>
                                                        <Col sm="2">
                                                            <Switch
                                                                uncheckedIcon={<Offsymbol />}
                                                                checkedIcon={<OnSymbol />}
                                                                onColor="#626ed4"
                                                                onChange={() => { handleProvider(provider) }}
                                                                checked={this.isProviderEnabled(account, provider)}
                                                            />
                                                        </Col>
                                                        <Col sm="4">
                                                            <select name="configuration" className="form-control" defaultValue={this.GetConfigurationID(provider, account.providers)} onChange={(e) => handleConfiguration(e.target.value)} >
                                                                <option disabled value="0">Please select configuration</option>
                                                                {this.GetAvailableConfigurations(provider).map((configuration, index) => (
                                                                    <option key={index} value={configuration.id}>{configuration.name}</option>
                                                                ))}
                                                            </select>
                                                        </Col>
                                                        <Col sm="2">
                                                            <Input type="number" name="price" placeholder="0.00 %" value={this.GetProviderInfo(account, provider).price || "0"} onChange={(e) => { handleProvider(provider, e) }} />
                                                            <span className="text-muted" style={{ fontSize: "10px" }} >{"The percentage of GGR to which the product is supplied - Max.(" + this.GetProviderInfo(authAccount, provider).price + ")"}</span>
                                                        </Col>
                                                    </> :
                                                    <>
                                                        <Col sm="3">
                                                            <label htmlFor={"Provider-" + provider.name}>{provider.name}</label>
                                                        </Col>
                                                        <Col sm="2">
                                                            <Switch
                                                                uncheckedIcon={<Offsymbol />}
                                                                checkedIcon={<OnSymbol />}
                                                                onColor="#626ed4"
                                                                onChange={() => { handleProvider(provider) }}
                                                                checked={this.isProviderEnabled(account, provider)}
                                                            />
                                                        </Col>
                                                        <Col sm="4">
                                                            <select name="configuration" className="form-control" defaultValue={this.GetConfigurationID(provider, account.providers)} onChange={(e) => handleConfiguration(e.target.value)} >
                                                                <option disabled value="0">Please select configuration</option>
                                                                {this.GetAvailableConfigurations(provider).map((configuration, index) => (
                                                                    <option key={index} value={configuration.id}>{configuration.name}</option>
                                                                ))}
                                                            </select>
                                                        </Col>
                                                        <Col sm="3">
                                                            <Input type="number" name="price" placeholder="0.00 %" value={this.GetProviderInfo(account, provider).price || "0"} onChange={(e) => { handleProvider(provider, e) }} />
                                                            <span className="text-muted" style={{ fontSize: "10px" }} >{"The percentage of GGR to which the product is supplied - Max.(" + this.GetProviderInfo(authAccount, provider).price + ")"}</span>
                                                        </Col>
                                                    </>}
                                            </Row>))}
                                    </>
                                }
                            </CardBody>
                        </Card>
                    </div>
                </div>
                <div className="modal-footer">
                    <button type="button" onClick={close} className="btn btn-secondary waves-effect" data-dismiss="modal">
                        Close
                    </button>
                    <button type="button" className="btn btn-primary waves-effect waves-light" onClick={save} disabled={disabled} >
                        Save
                    </button>
                </div>
            </Modal>);
    }
}

export default AccountModal;