import React, { Component } from "react";
import { Row, Col, Input } from "reactstrap";
import Chip from '@material-ui/core/Chip';



class InputForm extends Component {

    constructor(props) {

        super(props);
    }



    handleDelete() {

    }



    render() {

        let { name, label, type, value, placeholder, handleChange, text, htmlFor, chips } = this.props;

        if (value[name] != null && value[name].length > 0) {

            if (value[name].indexOf(',') !== -1)
                value[name] = value[name].split(',');

            if (Array.isArray(value[name]))
                chips = value[name];
        }

        if (handleChange == null)
            handleChange = () => {};

        return (
            <Row className="form-group">
                {label != null ?
                    <label htmlFor={htmlFor || name} className="col-sm-2 col-form-label">
                        {label}
                    </label> : null}
                <Col sm={label != null ? 10 : 12}>
                    {chips != null ?
                        chips.map((data, index) => {
                            return (
                                <Chip key={index}
                                    label={data}
                                    onDelete={this.handleDelete}
                                />
                            );
                        }) : null
                    }
                    <Input type={type} name={name} placeholder={placeholder} value={value[name] || ""} onChange={e => handleChange(e)} />
                    {text ? <span className="text-muted" style={{ fontSize: "10px" }} > {text} </span> : null}
                </Col>
            </Row>);
    }
}

export default InputForm;